$dimmed-text: #89899d;

footer {
  background-color: $primary;
  color: white;

  .h1,
  .h2,
  .h3 {
    color: #89899d;
  }
  a {
    &.facebook,
    &.twitter,
    &.youtube {
      position: relative;
      padding: 0 1rem 0 2rem;
      text-decoration: none;
      display: inline-block;
      &:hover,
      &:focus {
        &::before {
          left: 0.7rem;
        }
      }
      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 4px;
        margin-top: -1px;
        width: 18px;
        height: 18px;
        transition: all 0.3s;
      }
    }

    &.facebook::before {
      background: url(../images/ikoner/ikon-facebook--hvid.svg) 18px 18px /
        contain;
    }

    &.twitter::before {
      background: url(../images/ikoner/ikon-twitter--hvid.svg) 18px 18px /
        contain;
    }

    &.youtube::before {
      background: url(../images/ikoner/ikon-youtube--hvid.svg) 18px 18px /
        contain;
    }
  }
  a,
  a:hover,
  a:focus {
    color: white;
  }

  a:focus {
    box-shadow: $alt-btn-focus;
    background-color: $blaa-60;
    outline: none;
  }

  address {
    margin-bottom: 0;
  }

  .footer-links {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0.5rem 0;
  }

  .footer-info {
    color: $dimmed-text;
    border-top: 1px solid $blaa-80;
    padding: 1.5rem 1rem 1.5rem;
  }
}
