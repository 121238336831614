$block-table-border-color: #e3dbda;

.block-table {
  &:not(&--plain) {
    tbody > tr:nth-child(odd) {
      background-color: $block-table-border-color;
    }
  }

  &.block-table--plain {
    tbody > tr {
      border-top: solid 1px $block-table-border-color;

      &:last-child {
        border-bottom: solid 1px $block-table-border-color;
      }
    }
  }
}

@media (max-width: 768px) {
  .block-table {
    tbody td {
      display: block;
      border: none !important;
      &:before {
        display: block;
        font-weight: bold;
      }
    }

    thead tr {
      display: none;
    }

    &.block-table--2-col tbody td {
      text-align: right;

      &:before {
        float: left;
        margin-right: 5px;
      }
    }
  }
}
