@media print {
  header {
    .bg-primary {
      background-color: transparent !important;
    }

    .header-element {
      color: transparent;
      background-color: transparent !important;
      &.bg-skatdk {
        color: transparent;
      }
      &.skatdklogo {
        background-image: url(../images/skatdk-logo-print.svg);
      }
    }
  }

  .with-caret::after {
    content: none;
  }

  .infocontent {
    display: inline-block !important;
    position: static;
    background-color: transparent !important;
    padding-top: 0 !important;
    text-align: right;

    p {
      color: black;
    }

    button {
      display: none !important  ;
    }
  }
}
