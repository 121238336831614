.topmenu {
  &__link {
    color: white;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    position: relative;
    padding: 0.5rem 1rem 0.5rem 2.5rem;
    transition: all 0.3s;
    background: $blaa;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);

    &:not(.active)::before {
      content: '';
      display: block;
      position: absolute;
      left: 16px;
      top: calc(50% - 4px);
      width: 8px;
      height: 8px;
      border: solid currentColor;
      border-width: 2px 2px 0 0;
      transform: rotate(45deg);
      transition: all 0.3s;
    }

    &.active {
      font-weight: bold;

      &::before {
        font-family: $icomoon-font-family;
        font-weight: normal;
        display: inline-block;
        content: $ikon-ok;
        margin-right: 1rem;
        font-size: 11px;
        position: absolute;
        left: 16px;
      }
    }

    &:hover,
    &:focus {
      background: $blaa-60;
      text-decoration: none;
      color: #fff;

      &:not(.active) {
        &::before {
          left: 0.7rem;
        }
      }
    }
  }
}
