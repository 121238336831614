// @include media-breakpoint-down(sm) {
//   padding: 0 0px;
//   overflow-x: hidden;

//   > .row {
//     margin: 0 -16px;
//   }
// }

.site-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.site-wrapper > .container,
.site-wrapper > .container-fluid {
  flex-grow: 1;
  margin-bottom: 5rem;
}

.container-max {
  max-width: 1800px !important;
}

.container {
  &.container-900 {
    max-width: 900px;
  }

  &.container-1200 {
    max-width: 1200px;
  }
}

.mw-text {
  max-width: 78rem !important;
}

.border-large {
  border-width: 5px !important;
}

$section-spacer: 8px;
$section-spacer-small: 1px;
$section-spacer-large: 16px;

.spacer {
  &.row {
    height: $section-spacer;

    &.small {
      height: $section-spacer-small;
    }

    &.large {
      height: $section-spacer-large;
    }
  }

  &.col-auto {
    padding: 0;
    width: $section-spacer;

    &.small {
      width: $section-spacer-small;
    }

    &.large {
      width: $section-spacer-large;
    }
  }
}

.bg-body {
  background-color: $body-bg;
}

body {
  ::selection {
    color: white;
    background-color: $blaa;
  }
  .bg-primary,
  .text-white,
  .alert-primary,
  .btn-primary,
  .action-btn-arrow,
  footer {
    ::selection {
      color: $blaa;
      background-color: white;
    }
  }
}
