@import 'colors';

ul.link-list {
  padding-left: 0;
  padding-bottom: 1rem;
  margin: 0;
  list-style-type: none;

  li {
    padding: 0.5em 0;

    &.active {
      position: relative;
      font-weight: bold;

      &::before {
        content: '';
        position: absolute;
        width: 0.2rem;
        height: 0.7em;
        margin-right: 0;
        display: inline-block;
        background-color: $blaa;
        top: 0.9rem;
        left: -1.5rem;
      }
    }

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
