@use 'sass:math';

.blue-square {
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 2.2rem;
    height: 0.6rem;
    background: #14143c;
    position: absolute;
    left: math.div($grid-gutter-width, 2);
    top: 0px;
  }

  &[class*='col'] {
    padding-top: 16px;
  }
}
