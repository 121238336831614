.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
  padding-right: 30px;
  margin-top: auto;
  margin-bottom: auto;
}

.dropdown {
  &.action-dropdown {
    .dropdown-toggle {
      border: none;
    }
  }
  &-toggle {
    background-color: $blaa;
    color: white;
    border: solid 1px $blaa;
    padding: 0.75rem 1rem;
    transition: background-color 0.3s;

    &--light {
      background-color: white;
      color: $blaa;
    }

    &:focus {
      outline: none;
    }

    h1 &,
    h2 & {
      background: white;
      color: inherit;
      font-weight: inherit;
      border: solid 1px $blaa;

      &:focus {
        outline: none;
        box-shadow: $btn-focus-box-shadow;
      }

      &:hover,
      &:active {
        background-color: $blaa-20;
      }
    }

    h1 & {
      padding: 1rem 1.5rem;
    }

    h2 & {
      padding: 0.8rem 1.1rem;
    }

    &::after {
      border-top-width: $caret-width * 1.5;
      transition: transform ease-in-out 0.2s;
    }

    &[aria-expanded='true']::after {
      transform: rotate(0.5turn);
    }
  }
  &-menu {
    margin: 0;
    padding-left: 1rem padding-right 0.8rem;
    h1 &,
    h2 & {
      margin-top: -1px;
      border: none;
      padding: 0;
      min-width: auto;
    }
  }
  &-item {
    cursor: pointer;
    transition: background-color 0.3s;
    h1 &,
    h2 & {
      $drop-item-border-style: 1px solid #14143c;
      border-bottom: none;

      &:last-child {
        border-bottom: $drop-item-border-style;
      }

      &:active,
      &.active {
        color: $blaa;
        background-color: $blaa-20;
      }

      @include hover-focus {
        outline: none;
        background-color: $blaa-20;
      }
    }

    h1 & {
      padding: 1rem 1.5rem;
    }

    h2 & {
      padding: 0.8rem 1.1rem;
    }
  }
}

@include media-breakpoint-down(sm) {
  // Automatically set modal's width for larger viewports
  .dropdown {
    padding-right: 0;
    background-color: red;
    display: inline-block;
  }
}

.alert.filter {
  display: inline-flex;
  align-items: center;
  border: solid 1px $blaa;
  padding: 0.25rem 0.25rem 0.25rem 0.5rem;
  position: relative;
  margin-bottom: 1rem;
  margin-right: 1rem;

  .close {
    height: auto;
    width: auto;
    position: relative;
    opacity: 1;
    font-size: 1.7rem;
    color: #14143c;
    padding: 0 0.25rem;
    margin-left: 0.25rem;
    display: flex;
    align-items: center;

    &:focus {
      outline: none;
    }
  }
}
