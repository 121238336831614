.transport-box {
  color: #14143c;
  text-decoration: none;
  border-top: $border-width solid $baggrund-graa;
  border-right: $border-width solid #ffffff;
  border-bottom: $border-width solid $baggrund-graa;
  border-left: $border-width solid $baggrund-graa;
  background: white;
  margin: 0;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &:hover {
    border: $border-width solid #14143c;
    color: #14143c;
    text-decoration: none;
  }

  &:focus {
    z-index: 10;

    p {
      color: $blaa-60;
    }
  }

  h2 {
    flex-grow: 1;
    font-weight: 600;
    margin: 0 0 1rem 0;
  }

  p {
    margin-bottom: 0;
    position: relative;
    display: flex;
    color: #14143c;

    &:before {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      flex-shrink: 0;
      margin-right: 1rem;
      margin-top: 0.5rem;
      border-style: solid;
      border-width: 2px 2px 0 0;
      transform: rotate(45deg);
      transition: all 0.3s;
    }
  }

  &:focus p::before,
  &:hover p::before {
    transform: translateX(0.4rem) rotate(45deg);
  }

  @include media-breakpoint-up(md) {
    &:nth-child(odd) {
      border-right: $border-width solid $baggrund-graa;
    }
    &:last-child {
      border-right: $border-width solid #ffffff;
    }

    &.transport-box--column {
      border-right-color: white;
      display: block;
    }
    &:hover {
      border: $border-width solid #14143c;
    }
  }

  @include media-breakpoint-up(lg) {
    border-right: $border-width solid $baggrund-graa;

    &:nth-child(3n + 3) {
      border-right: $border-width solid #ffffff;
    }
    &.transport-box--column {
      border-right-color: white;
    }
    &:hover {
      border: $border-width solid #14143c;
    }
  }
}
