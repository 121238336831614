@use 'sass:math';
@import 'colors';

$font-family-sans-serif: 'Academy Sans', Arial, sans-serif;
$small-font-size: 75%;
$body-bg: $baggrund-graa;
$body-color: $blaa;
$code-color: #c73578;
$grid-gutter-width: 48px;

$yellow: #ffbb16;
$red: #df0515;
$primary: $blaa;

$paragraph-margin-bottom: 0.7rem;

$link-color: $blaa;
$link-hover-color: $blaa-80;
$link-decoration: underline;

$border-width: 1px;
$border-color: $baggrund-graa;
$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;
$hr-border-color: $baggrund-graa;

$h1-font-size: 2.8125rem;
$h2-font-size: 1.5rem;
$h3-font-size: 1.25rem;
$h4-font-size: 1rem;
$headings-font-weight: 100;
$headings-margin-bottom: 0.5rem;

$breadcrumb-bg: transparent;
$breadcrumb-item-padding: 0;
$breadcrumb-divider: '';
$breadcrumb-padding-y: math.div($grid-gutter-width, 2);
$breadcrumb-padding-x: math.div($grid-gutter-width, 2);
$breadcrumb-active-color: $blaa-60;

$navbar-padding-y: 0;
$navbar-padding-x: 0;
$navbar-nav-link-padding-x: 1.125rem;

$nav-link-padding-y: 0.8rem;
$nav-link-padding-x: 2rem;
$nav-link-disabled-color: $blaa-40;

$nav-tabs-link-active-color: white;
$nav-tabs-link-active-bg: $blaa-80;
$nav-tabs-border-color: #72728a;
$navbar-dark-color: white;

$btn-focus-box-shadow: 0 0 0 0.3rem $blaa-60;
$input-focus-box-shadow: 0 0 0 0.2rem $blaa-60;
$custom-select-focus-box-shadow: $input-focus-box-shadow;
$input-btn-padding-y: 1rem;
$input-btn-padding-x: 1rem;
$input-group-addon-bg: white;
$input-disabled-bg: $graa;
$input-height: 3.125rem;
$input-padding-y: 0.75rem;
$enable-validation-icons: false;
$form-feedback-valid-color: $blaa;
$input-border-color: $primary;
$input-color: $primary;
$input-placeholder-color: #6b6b84;

$custom-control-indicator-size: 1.5rem;
$custom-control-indicator-border-color: $blaa;
$custom-control-indicator-border-width: 2px;
$custom-control-indicator-focus-box-shadow: 0 0 0 2px white, 0 0 0 6px $blaa-60;
$custom-control-indicator-focus-border-color: $blaa-80;
$custom-control-indicator-active-border-color: $blaa-60;
$custom-control-indicator-active-bg: $blaa-60;
$custom-radio-indicator-icon-checked: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23FFF' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); //url("data:image/svg+xml,%3csvg width='8' height='8' xmlns='http://www.w3.org/2000/svg'%3e%3cg transform='rotate(45 4,3.5)' id='svg_3'%3e%3crect fill='#FFFFFF' x='4' y='0' width='2' height='7' id='svg_1'/%3e%3crect fill='#FFFFFF' x='2' y='5' width='4' height='2' id='svg_2'/%3e%3c/g%3e%3c/svg%3e");
$custom-checkbox-indicator-icon-inverted-checked: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='#{$blaa}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"),
  '#',
  '%23'
);
$custom-checkbox-indicator-icon-inverted-indeterminate: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='#{$blaa}' d='M0 2h4'/%3e%3c/svg%3e"),
  '#',
  '%23'
);
$custom-checkbox-indicator-border-radius: 0.25rem;
$custom-control-gutter: 0.75rem;
$custom-control-spacer-x: 1.5rem;

$alert-bg-level: 0;
$alert-border-level: 0;
$alert-color-level: -12;
$alert-padding-y: 1.875rem;
$alert-padding-x: 1.875rem;

$table-color: $blaa-80;
$table-cell-padding: 1.5rem;
$table-border-width: 0.5px;
$table-hover-bg: $baggrund-graa;

$modal-footer-border-width: 0;

$caret-width: 0.2em;
$caret-vertical-align: $caret-width;
$caret-spacing: $caret-width;

$popover-border-width: 2px;
$popover-border-color: $blaa;
$popover-body-padding-y: 1rem;
$popover-body-padding-x: 1rem;

$popover-arrow-height: 1rem;
