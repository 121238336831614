@import '../../fonts/ikoner/variables.scss';

.nav-item {
  position: relative;
  .nav-link {
    &.active {
      font-weight: 600;
      padding-bottom: 8px;
      border-bottom: 2px #fff solid;
    }
    color: white;
    transition: background-color 0.3s;
    &:hover {
      cursor: pointer;
      color: currentColor;
      box-shadow: 0 0 0 0 $blaa-40;
      outline: none;
      text-decoration: none;
      text-decoration: none;
      border-bottom: 2px #fff solid;
    }
    &:focus {
      &:not(.active) {
        color: white;
        border-color: $blaa-60;
      }
    }
  }
}
