// Denne er deprecated
@use 'sass:math';

$height: 5rem;
$logo-height: 3rem;
$toggle-size: 0.6em;

header {
  .styrelsesheader {
    background-color: $primary;
    position: relative;
    color: white;
    height: $height;
    display: flex;

    &__right {
      margin-left: auto;
      position: relative;
      overflow: hidden;

      &.showinfo {
        overflow: visible;

        .styrelsesheader__infotoggler {
          &:after {
            transform: rotate(0.5turn);
          }
        }
      }
    }

    &__logo {
      padding: math.div(($height - $logo-height), 2) 0.5rem;
      height: $height;

      &.skatdk {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
      }
    }

    &__infotoggler {
      border: none;
      background-color: #2e99d9;
      height: 100%;
      margin-left: auto;
      text-align: left;
      padding: 0 1rem;
      color: inherit;
      display: flex;
      align-items: center;

      &:after {
        content: '';
        display: block; //IE vil have elementet skal være defineret som block
        transition: transform ease-in-out 0.2s;
        @include trekant($toggle-size);
        margin-left: 1rem;
      }
    }

    .styrelsesheader__info {
      padding: 1.5rem 1rem;
      text-align: left;
      background-color: #2e99d9;
    }
  }
}

@each $name, $styrelse in $styrelser {
  .#{$name} header {
    .styrelsesheader {
      &__right {
        color: map-get($map: $styrelse, $key: text-kontrast);
        p {
          color: map-get($map: $styrelse, $key: text-kontrast);
        }
      }
    }
  }
}
