a.related-link {
  position: relative;
  margin-bottom: 0.7rem;
  padding: 0 1rem 0 2rem;
  text-decoration: none;
  display: inline-block;

  &:hover,
  &:focus {
    color: white;
    text-decoration: none;
    outline: none;
    background: #72728a;

    &::before,
    &::before {
      left: 0.7rem;
    }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0.75rem;
    margin-top: -4px;
    width: 8px;
    height: 8px;
    border: solid currentColor;
    border-width: 2px 2px 0 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    transition: all 0.3s;
  }
}
