@import 'pre-bs-include';

// Required by Bootstrap
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins.scss';

// Optional Bootstrap elements
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/breadcrumb';
// @import "~bootstrap/scss/code";
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/custom-forms';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/input-group';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/popover';
/*
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/carousel";
*/
@import '~bootstrap/scss/utilities.scss';
@import '~bootstrap/scss/print';

@import 'post-bs-include';
