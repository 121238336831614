@charset "UTF-8";
@font-face {
  font-family: "dap-ikon-font";
  src: url("../fonts/ikoner/dap-ikon-font.ttf?6uk9a0") format("truetype"), url("../fonts/ikoner/dap-ikon-font.woff?6uk9a0") format("woff"), url("../fonts/ikoner/dap-ikon-font.svg?6uk9a0#dap-ikon-font") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.ikon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "dap-ikon-font" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ikon-sprogvaelger:before {
  content: "\e984";
}

.ikon-se:before {
  content: "\e983";
}

.ikon-skift-kunde:before {
  content: "\e982";
}

.ikon-start-forfra:before {
  content: "\e981";
}

.ikon-nulindberet:before {
  content: "\e97f";
}

.ikon-kopier:before {
  content: "\e980";
}

.ikon-maalflag:before {
  content: "\e97e";
}

.ikon-ok:before {
  content: "\e97d";
}

.ikon-tilfoej:before {
  content: "\e97c";
}

.ikon-print:before {
  content: "\e979";
}

.ikon-gem:before {
  content: "\e978";
}

.ikon-slet:before {
  content: "\e977";
}

.ikon-rediger:before {
  content: "\e976";
}

.ikon-skatten-2019:before {
  content: "\e975";
}

.ikon-godt-fra-start:before {
  content: "\e973";
}

.ikon-skatten-2018:before {
  content: "\e974";
}

.ikon-gaeld:before {
  content: "\e972";
}

.ikon-afloeb-draen:before {
  content: "\e92e";
}

.ikon-afskrivning:before {
  content: "\e92f";
}

.ikon-asbest-pcb-bly:before {
  content: "\e930";
}

.ikon-beregn:before {
  content: "\e931";
}

.ikon-boede:before {
  content: "\e932";
}

.ikon-boernepasning:before {
  content: "\e933";
}

.ikon-braendeovne:before {
  content: "\e934";
}

.ikon-doedsfald:before {
  content: "\e935";
}

.ikon-ejendomsvurdering:before {
  content: "\e936";
}

.ikon-excel:before {
  content: "\e937";
}

.ikon-fjernvarmeunits:before {
  content: "\e938";
}

.ikon-flytning:before {
  content: "\e939";
}

.ikon-formue:before {
  content: "\e93a";
}

.ikon-glasdoere:before {
  content: "\e93b";
}

.ikon-havearbejde:before {
  content: "\e93c";
}

.ikon-henvendelser:before {
  content: "\e93d";
}

.ikon-hjaelp:before {
  content: "\e93e";
}

.ikon-indenfor-eu:before {
  content: "\e93f";
}

.ikon-indstillinger:before {
  content: "\e940";
}

.ikon-information:before {
  content: "\e941";
}

.ikon-installation-af-varmepumper:before {
  content: "\e942";
}

.ikon-integrer:before {
  content: "\e943";
}

.ikon-intelligent-styring:before {
  content: "\e944";
}

.ikon-isolering-af-gulv:before {
  content: "\e945";
}

.ikon-isolering-af-tag:before {
  content: "\e946";
}

.ikon-isolering-af-ydervaegge:before {
  content: "\e947";
}

.ikon-job:before {
  content: "\e948";
}

.ikon-kalender:before {
  content: "\e949";
}

.ikon-kontakt:before {
  content: "\e94a";
}

.ikon-kontekstuel-lovgivning:before {
  content: "\e94b";
}

.ikon-ladestik-til-elbiler:before {
  content: "\e94c";
}

.ikon-loenindkomst:before {
  content: "\e94d";
}

.ikon-lyt:before {
  content: "\e94e";
}

.ikon-pdf:before {
  content: "\e94f";
}

.ikon-pension:before {
  content: "\e950";
}

.ikon-permeable-belaegninger:before {
  content: "\e951";
}

.ikon-play:before {
  content: "\e952";
}

.ikon-problem:before {
  content: "\e954";
}

.ikon-punktafgifter:before {
  content: "\e955";
}

.ikon-radonsikring:before {
  content: "\e956";
}

.ikon-registreringsafgift:before {
  content: "\e957";
}

.ikon-rengoering:before {
  content: "\e958";
}

.ikon-renteudgifter-oevrige:before {
  content: "\e959";
}

.ikon-raadgivere-revisorer:before {
  content: "\e95a";
}

.ikon-raadgivning:before {
  content: "\e95b";
}

.ikon-segment:before {
  content: "\e95c";
}

.ikon-send:before {
  content: "\e95d";
}

.ikon-servicedesk:before {
  content: "\e95e";
}

.ikon-skattekort:before {
  content: "\e95f";
}

.ikon-skorsten:before {
  content: "\e960";
}

.ikon-solafskaermning:before {
  content: "\e961";
}

.ikon-starting-a-business:before {
  content: "\e962";
}

.ikon-su:before {
  content: "\e963";
}

.ikon-tilslutning-til-bredbaand:before {
  content: "\e964";
}

.ikon-transparens:before {
  content: "\e965";
}

.ikon-transport:before {
  content: "\e966";
}

.ikon-udvendigt-malerarbejde:before {
  content: "\e967";
}

.ikon-udvidet-indberetning:before {
  content: "\e968";
}

.ikon-unge:before {
  content: "\e969";
}

.ikon-upload:before {
  content: "\e96a";
}

.ikon-varmeanlaeg:before {
  content: "\e96b";
}

.ikon-ventilation:before {
  content: "\e96c";
}

.ikon-vindmoeller:before {
  content: "\e96d";
}

.ikon-vinduespudsning:before {
  content: "\e96e";
}

.ikon-virksomhed:before {
  content: "\e96f";
}

.ikon-yderdoere:before {
  content: "\e970";
}

.ikon-aarsopgoerelsen:before {
  content: "\e971";
}

.ikon-skatten-2016:before {
  content: "\e92d";
}

.ikon-menu:before {
  content: "\e92c";
}

.ikon-dagsbevis:before {
  content: "\e91d";
}

.ikon-eindkomst:before {
  content: "\e91e";
}

.ikon-facebook:before {
  content: "\e91f";
}

.ikon-forskning:before {
  content: "\e920";
}

.ikon-haandvaerkerfradrag:before {
  content: "\e921";
}

.ikon-instagram:before {
  content: "\e922";
}

.ikon-linkedin:before {
  content: "\e923";
}

.ikon-omregistrering:before {
  content: "\e924";
}

.ikon-pinterest:before {
  content: "\e925";
}

.ikon-skatten-2015:before {
  content: "\e926";
}

.ikon-skatten-2017:before {
  content: "\e927";
}

.ikon-twitter:before {
  content: "\e928";
}

.ikon-udland:before {
  content: "\e929";
}

.ikon-virksomhedsregistrering:before {
  content: "\e92a";
}

.ikon-youtube:before {
  content: "\e92b";
}

.ikon-loensumsafgift:before {
  content: "\e91b";
}

.ikon-cykel:before {
  content: "\e917";
}

.ikon-dk-flag:before {
  content: "\e918";
}

.ikon-ekapital:before {
  content: "\e919";
}

.ikon-kuffert:before {
  content: "\e91a";
}

.ikon-more:before {
  content: "\e91c";
}

.ikon-underholdsbidrag:before {
  content: "\e916";
}

.ikon-afgifter:before {
  content: "\e900";
}

.ikon-aktier:before {
  content: "\e901";
}

.ikon-ansatte-og-loen:before {
  content: "\e902";
}

.ikon-arrow-right:before {
  content: "\e903";
}

.ikon-arrow-left:before {
  content: "\e97b";
}

.ikon-bil:before {
  content: "\e904";
}

.ikon-borger:before {
  content: "\e905";
}

.ikon-ejendom:before {
  content: "\e906";
}

.ikon-erhverv:before {
  content: "\e907";
}

.ikon-foreninger:before {
  content: "\e908";
}

.ikon-fradrag:before {
  content: "\e909";
}

.ikon-gaver:before {
  content: "\e90a";
}

.ikon-hjaelp-til-tastselv:before {
  content: "\e90b";
}

.ikon-indkomst-og-skat:before {
  content: "\e90c";
}

.ikon-ivaerksaetter:before {
  content: "\e90d";
}

.ikon-log-ind:before {
  content: "\e90e";
}

.ikon-luk:before {
  content: "\e90f";
}

.ikon-laan:before {
  content: "\e910";
}

.ikon-moms:before {
  content: "\e911";
}

.ikon-personalegoder:before {
  content: "\e912";
}

.ikon-skattekonto:before {
  content: "\e913";
}

.ikon-soeg:before {
  content: "\e914";
}

.ikon-told:before {
  content: "\e915";
}

.ikon-betalings-id:before {
  content: "\e97a";
}

.ikon-log-ud:before {
  content: "\e953";
}

.bg-graa {
  background-color: #e5e5e5;
}

.text-graa {
  color: #e5e5e5;
}

.bg-sand {
  background-color: #d1c5c3;
}

.text-sand {
  color: #d1c5c3;
}

.bg-skatdk {
  background-color: #2e99d9;
}

.text-skatdk {
  color: #2e99d9;
}

.bg-ufst {
  background-color: #1c69b9;
}

.text-ufst {
  color: #1c69b9;
}

.bg-adst {
  background-color: #aac800;
}

.text-adst {
  color: #aac800;
}

.bg-motorst {
  background-color: #008139;
}

.text-motorst {
  color: #008139;
}

.bg-toldst {
  background-color: #ffbb16;
}

.text-toldst {
  color: #ffbb16;
}

.bg-vurdst {
  background-color: #ff620c;
}

.text-vurdst {
  color: #ff620c;
}

.bg-gaeldst {
  background-color: #df0515;
}

.text-gaeldst {
  color: #df0515;
}

.bg-sktst {
  background-color: #aecbda;
}

.text-sktst {
  color: #aecbda;
}

.bg-skatdk.bg-hover, .bg-skatdk.bg-focus {
  transition: background-color 0.3s;
}
.bg-skatdk.bg-hover:hover, .bg-skatdk.bg-focus:focus {
  background-color: #77a5d5;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Academy Sans", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #14143c;
  text-align: left;
  background-color: #e5e5e5;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 0.7rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #14143c;
  text-decoration: underline;
  background-color: transparent;
}
a:hover {
  color: #434363;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, h2 > .collapser__header, .h3, h3 > .collapser__header, .h4, .collapser__header.h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 100;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.8125rem;
}

h2, .h2, h2 > .collapser__header {
  font-size: 1.5rem;
}

h3, .h3, h3 > .collapser__header {
  font-size: 1.25rem;
}

h4, .h4, .collapser__header.h4 {
  font-size: 1rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #e5e5e5;
}

small,
.small {
  font-size: 75%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 75%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 24px;
  padding-left: 24px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -24px;
  margin-left: -24px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 24px;
  padding-left: 24px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.btn {
  display: inline-block;
  font-weight: 400;
  color: #14143c;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 1rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #14143c;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.3rem #72728a;
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #14143c;
  border-color: #14143c;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0a0a1f;
  border-color: #070716;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0a0a1f;
  border-color: #070716;
  box-shadow: 0 0 0 0.2rem rgba(55, 55, 89, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #14143c;
  border-color: #14143c;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #070716;
  border-color: #04040c;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(55, 55, 89, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffbb16;
  border-color: #ffbb16;
}
.btn-warning:hover {
  color: #212529;
  background-color: #efa900;
  border-color: #e2a000;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #efa900;
  border-color: #e2a000;
  box-shadow: 0 0 0 0.2rem rgba(222, 165, 25, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffbb16;
  border-color: #ffbb16;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #e2a000;
  border-color: #d59700;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 165, 25, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #df0515;
  border-color: #df0515;
}
.btn-danger:hover {
  color: #fff;
  background-color: #ba0411;
  border-color: #ad0410;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #ba0411;
  border-color: #ad0410;
  box-shadow: 0 0 0 0.2rem rgba(228, 43, 56, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #df0515;
  border-color: #df0515;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ad0410;
  border-color: #a1040f;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(228, 43, 56, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #14143c;
  border-color: #14143c;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #14143c;
  border-color: #14143c;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(20, 20, 60, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #14143c;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #14143c;
  border-color: #14143c;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(20, 20, 60, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffbb16;
  border-color: #ffbb16;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffbb16;
  border-color: #ffbb16;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 187, 22, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffbb16;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffbb16;
  border-color: #ffbb16;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 187, 22, 0.5);
}

.btn-outline-danger {
  color: #df0515;
  border-color: #df0515;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #df0515;
  border-color: #df0515;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 5, 21, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #df0515;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #df0515;
  border-color: #df0515;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 5, 21, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #14143c;
  text-decoration: underline;
}
.btn-link:hover {
  color: #434363;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 24px 24px;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0;
  color: #6c757d;
  content: "";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #72728a;
}

.alert {
  position: relative;
  padding: 1.875rem 1.875rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 5.25rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.875rem 1.875rem;
  color: inherit;
}

.alert-primary {
  color: #f6f6f7;
  background-color: #14143c;
  border-color: #14143c;
}
.alert-primary hr {
  border-top-color: #0e0e29;
}
.alert-primary .alert-link {
  color: #dbdbdf;
}

.alert-secondary {
  color: #f9f9fa;
  background-color: #6c757d;
  border-color: #6c757d;
}
.alert-secondary hr {
  border-top-color: #60686f;
}
.alert-secondary .alert-link {
  color: #dddde3;
}

.alert-success {
  color: #f6fbf8;
  background-color: #28a745;
  border-color: #28a745;
}
.alert-success hr {
  border-top-color: #23923d;
}
.alert-success .alert-link {
  color: #d3ebdd;
}

.alert-info {
  color: #f6fbfc;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.alert-info hr {
  border-top-color: #148ea1;
}
.alert-info .alert-link {
  color: #d0eaef;
}

.alert-warning {
  color: #fffcf6;
  background-color: #ffbb16;
  border-color: #ffbb16;
}
.alert-warning hr {
  border-top-color: #fcb200;
}
.alert-warning .alert-link {
  color: #ffebc3;
}

.alert-danger {
  color: #fef5f6;
  background-color: #df0515;
  border-color: #df0515;
}
.alert-danger hr {
  border-top-color: #c60413;
}
.alert-danger .alert-link {
  color: #f9c7cc;
}

.alert-light {
  color: white;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.alert-light hr {
  border-top-color: #e9ecef;
}
.alert-light .alert-link {
  color: #e6e6e6;
}

.alert-dark {
  color: #f7f7f7;
  background-color: #343a40;
  border-color: #343a40;
}
.alert-dark hr {
  border-top-color: #292d32;
}
.alert-dark .alert-link {
  color: #dedede;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: 0 0 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 0 0;
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 0;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card-deck .card {
  margin-bottom: 24px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -24px;
    margin-left: -24px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 24px;
    margin-bottom: 0;
    margin-left: 24px;
  }
}

.card-group > .card {
  margin-bottom: 24px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #14143c;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #14143c;
  border-color: #14143c;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #0a0a1f;
  background-color: #bdbdc8;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #0a0a1f;
  background-color: #afafbc;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #0a0a1f;
  border-color: #0a0a1f;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #85610b;
  background-color: #ffecbe;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #85610b;
  background-color: #ffe5a5;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #85610b;
  border-color: #85610b;
}

.list-group-item-danger {
  color: #74030b;
  background-color: #f6b9bd;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #74030b;
  background-color: #f3a2a8;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #74030b;
  border-color: #74030b;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.8rem 2rem;
  text-decoration: none;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #a1a1b1;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #72728a;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #72728a;
}
.nav-tabs .nav-link.disabled {
  color: #a1a1b1;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: white;
  background-color: #434363;
  border-color: #dee2e6 #dee2e6 #434363;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #14143c;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 0;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.6125rem;
  padding-bottom: 0.6125rem;
  margin-right: 0;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 1.125rem;
    padding-left: 1.125rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 1.125rem;
    padding-left: 1.125rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.125rem;
    padding-left: 1.125rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 1.125rem;
    padding-left: 1.125rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: white;
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: white;
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='white' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: white;
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e5e5e5;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 0 solid #e5e5e5;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #434363;
}
.table th,
.table td {
  padding: 1.5rem;
  vertical-align: top;
  border-top: 0.5px solid #e5e5e5;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #e5e5e5;
}
.table tbody + tbody {
  border-top: 1px solid #e5e5e5;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 0.5px solid #e5e5e5;
}
.table-bordered th,
.table-bordered td {
  border: 0.5px solid #e5e5e5;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 1px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #434363;
  background-color: #e5e5e5;
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #bdbdc8;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #85859a;
}

.table-hover .table-primary:hover {
  background-color: #afafbc;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #afafbc;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffecbe;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdc86;
}

.table-hover .table-warning:hover {
  background-color: #ffe5a5;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe5a5;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f6b9bd;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ee7d85;
}

.table-hover .table-danger:hover {
  background-color: #f3a2a8;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f3a2a8;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: #e5e5e5;
}

.table-hover .table-active:hover {
  background-color: #d8d8d8;
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: #d8d8d8;
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #e5e5e5;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.form-control {
  display: block;
  width: 100%;
  height: 3.125rem;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #14143c;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #14143c;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #14143c;
  background-color: #fff;
  border-color: #34349c;
  outline: 0;
  box-shadow: 0 0 0 0.2rem #72728a;
}
.form-control::placeholder {
  color: #6b6b84;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e5e5e5;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #14143c;
}
select.form-control:focus::-ms-value {
  color: #14143c;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.75rem + 1px);
  padding-bottom: calc(0.75rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.75rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #14143c;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 75%;
  color: #14143c;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(20, 20, 60, 0.9);
  border-radius: 0;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #14143c;
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #14143c;
  box-shadow: 0 0 0 0.2rem rgba(20, 20, 60, 0.25);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #14143c;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #14143c;
  box-shadow: 0 0 0 0.2rem rgba(20, 20, 60, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #14143c;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #14143c;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #14143c;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #212162;
  background-color: #212162;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(20, 20, 60, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #14143c;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #14143c;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #14143c;
  box-shadow: 0 0 0 0.2rem rgba(20, 20, 60, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 75%;
  color: #df0515;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(223, 5, 21, 0.9);
  border-radius: 0;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #df0515;
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #df0515;
  box-shadow: 0 0 0 0.2rem rgba(223, 5, 21, 0.25);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #df0515;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #df0515;
  box-shadow: 0 0 0 0.2rem rgba(223, 5, 21, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #df0515;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #df0515;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #df0515;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #fa1d2d;
  background-color: #fa1d2d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(223, 5, 21, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #df0515;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #df0515;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #df0515;
  box-shadow: 0 0 0 0.2rem rgba(223, 5, 21, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 2.25rem;
  color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1.5rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1.5rem;
  height: 1.5rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #14143c;
  background-color: #14143c;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 2px white, 0 0 0 6px #72728a;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #434363;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #72728a;
  border-color: #72728a;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e5e5e5;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0rem;
  left: -2.25rem;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #14143c solid 2px;
}
.custom-control-label::after {
  position: absolute;
  top: 0rem;
  left: -2.25rem;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #14143c;
  background-color: #14143c;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(20, 20, 60, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(20, 20, 60, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23FFF' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(20, 20, 60, 0.5);
}

.custom-switch {
  padding-left: 3.375rem;
}
.custom-switch .custom-control-label::before {
  left: -3.375rem;
  width: 2.625rem;
  pointer-events: all;
  border-radius: 0.75rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0rem + 4px);
  left: calc(-3.375rem + 4px);
  width: calc(1.5rem - 8px);
  height: calc(1.5rem - 8px);
  background-color: #14143c;
  border-radius: 0.75rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(1.125rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(20, 20, 60, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: 3.125rem;
  padding: 0.75rem 2rem 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #14143c;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 1rem center/8px 10px no-repeat;
  border: 1px solid #14143c;
  border-radius: 0;
  appearance: none;
}
.custom-select:focus {
  border-color: #34349c;
  outline: 0;
  box-shadow: 0 0 0 0.2rem #72728a;
}
.custom-select:focus::-ms-value {
  color: #14143c;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 1rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #14143c;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 3.125rem;
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 3.125rem;
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #34349c;
  box-shadow: 0 0 0 0.2rem #72728a;
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e5e5e5;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 3.125rem;
  padding: 0.75rem 1rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #14143c;
  background-color: #fff;
  border: 1px solid #14143c;
  border-radius: 0;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 1.5rem);
  padding: 0.75rem 1rem;
  line-height: 1.5;
  color: #14143c;
  content: "Browse";
  background-color: white;
  border-left: inherit;
  border-radius: 0 0 0 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #e5e5e5, 0 0 0 0.2rem #72728a;
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #e5e5e5, 0 0 0 0.2rem #72728a;
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #e5e5e5, 0 0 0 0.2rem #72728a;
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #14143c;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #4242c0;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #14143c;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #4242c0;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #14143c;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #4242c0;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.2em;
  vertical-align: 0.2em;
  content: "";
  border-top: 0.2em solid;
  border-right: 0.2em solid transparent;
  border-bottom: 0;
  border-left: 0.2em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #14143c;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.2em;
  vertical-align: 0.2em;
  content: "";
  border-top: 0;
  border-right: 0.2em solid transparent;
  border-bottom: 0.2em solid;
  border-left: 0.2em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.2em;
  vertical-align: 0.2em;
  content: "";
  border-top: 0.2em solid transparent;
  border-right: 0;
  border-bottom: 0.2em solid transparent;
  border-left: 0.2em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.2em;
  vertical-align: 0.2em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.2em;
  vertical-align: 0.2em;
  content: "";
  border-top: 0.2em solid transparent;
  border-right: 0.2em solid;
  border-bottom: 0.2em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #14143c;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #14143c;
  text-align: center;
  white-space: nowrap;
  background-color: white;
  border: 1px solid #14143c;
  border-radius: 0;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 2rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #e5e5e5;
  border: 1px solid #dee2e6;
  border-radius: 0;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #14143c;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #434363;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(20, 20, 60, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #14143c;
  border-color: #14143c;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Academy Sans", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #14143c;
  border-radius: 0;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 1rem;
  margin: 0 0;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 1rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-1rem - 2px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 1rem 0.5rem 0;
  border-top-color: #14143c;
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 2px;
  border-width: 1rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 1rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-1rem - 2px);
  width: 1rem;
  height: 1rem;
  margin: 0 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 1rem 0.5rem 0;
  border-right-color: #14143c;
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 2px;
  border-width: 0.5rem 1rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 1rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-1rem - 2px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 1rem 0.5rem;
  border-bottom-color: #14143c;
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 2px;
  border-width: 0 0.5rem 1rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 2px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 1rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-1rem - 2px);
  width: 1rem;
  height: 1rem;
  margin: 0 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 1rem;
  border-left-color: #14143c;
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 2px;
  border-width: 0.5rem 0 0.5rem 1rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 2px solid #ebebeb;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #14143c;
}

/*
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/carousel";
*/
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #14143c !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #070716 !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffbb16 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #e2a000 !important;
}

.bg-danger {
  background-color: #df0515 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ad0410 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #e5e5e5 !important;
}

.border-top {
  border-top: 1px solid #e5e5e5 !important;
}

.border-right {
  border-right: 1px solid #e5e5e5 !important;
}

.border-bottom {
  border-bottom: 1px solid #e5e5e5 !important;
}

.border-left {
  border-left: 1px solid #e5e5e5 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #14143c !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffbb16 !important;
}

.border-danger {
  border-color: #df0515 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0 !important;
}

.rounded {
  border-radius: 0 !important;
}

.rounded-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-lg {
  border-radius: 0 !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #14143c !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #010103 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffbb16 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #c98e00 !important;
}

.text-danger {
  color: #df0515 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #94030e !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #14143c !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #e5e5e5;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #e5e5e5;
  }
}
.site-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.site-wrapper > .container,
.site-wrapper > .container-fluid,
.site-wrapper > .container-sm,
.site-wrapper > .container-md,
.site-wrapper > .container-lg,
.site-wrapper > .container-xl {
  flex-grow: 1;
  margin-bottom: 5rem;
}

.container-max {
  max-width: 1800px !important;
}

.container.container-900 {
  max-width: 900px;
}
.container.container-1200 {
  max-width: 1200px;
}

.mw-text {
  max-width: 78rem !important;
}

.border-large {
  border-width: 5px !important;
}

.spacer.row {
  height: 8px;
}
.spacer.row.small {
  height: 1px;
}
.spacer.row.large {
  height: 16px;
}
.spacer.col-auto {
  padding: 0;
  width: 8px;
}
.spacer.col-auto.small {
  width: 1px;
}
.spacer.col-auto.large {
  width: 16px;
}

.bg-body {
  background-color: #e5e5e5;
}

body ::selection {
  color: white;
  background-color: #14143c;
}
body .bg-primary ::selection,
body .text-white ::selection,
body .alert-primary ::selection,
body .btn-primary ::selection,
body .action-btn-arrow ::selection,
body footer ::selection {
  color: #14143c;
  background-color: white;
}

@font-face {
  font-family: "Academy Sans";
  src: url("../fonts/AcademySans/AcademySans-Regular.eot");
  src: url("../fonts/AcademySans/AcademySans-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/AcademySans/AcademySans-Regular.woff2") format("woff2"), url("../fonts/AcademySans/AcademySans-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "Academy Sans";
  src: url("../fonts/AcademySans/AcademySans-Italic.eot");
  src: url("../fonts/AcademySans/AcademySans-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/AcademySans/AcademySans-Italic.woff2") format("woff2"), url("../fonts/AcademySans/AcademySans-Italic.woff") format("woff");
  font-style: italic;
  font-display: fallback;
}
@font-face {
  font-family: "Academy Sans";
  src: url("../fonts/AcademySans/AcademySans-Medium.eot");
  src: url("../fonts/AcademySans/AcademySans-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/AcademySans/AcademySans-Medium.woff2") format("woff2"), url("../fonts/AcademySans/AcademySans-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "Academy Sans";
  src: url("../fonts/AcademySans/AcademySans-Demibold.eot");
  src: url("../fonts/AcademySans/AcademySans-Demibold.eot?#iefix") format("embedded-opentype"), url("../fonts/AcademySans/AcademySans-Demibold.woff2") format("woff2"), url("../fonts/AcademySans/AcademySans-Demibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "Academy Sans";
  src: url("../fonts/AcademySans/AcademySans-Bold.eot");
  src: url("../fonts/AcademySans/AcademySans-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/AcademySans/AcademySans-Bold.woff2") format("woff2"), url("../fonts/AcademySans/AcademySans-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "Academy Sans";
  src: url("../fonts/AcademySans/AcademySans-Black.eot");
  src: url("../fonts/AcademySans/AcademySans-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/AcademySans/AcademySans-Black.woff2") format("woff2"), url("../fonts/AcademySans/AcademySans-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: fallback;
}
h1,
.h1 {
  margin: 1rem 0 1rem;
  font-weight: 900;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
@media screen and (max-width: 576px) {
  h1,
.h1 {
    font-size: 9vw;
  }
}
h1.small,
.h1.small {
  font-size: 28px;
  font-weight: 900;
}

h2,
.h2,
h2 > .collapser__header {
  margin: 2rem 0 0.6rem;
}

h3,
.h3,
h3 > .collapser__header {
  margin: 2rem 0 0.4rem;
}

h4,
.h4,
.collapser__header.h4 {
  margin: 2rem 0 0.4rem;
}

h2,
.h2,
h2 > .collapser__header,
h3,
.h3,
h3 > .collapser__header,
h4,
.h4,
.collapser__header.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: 600;
}

h1 + h2,
h1 + .h2,
h2 > h1 + .collapser__header,
h1 + .table caption,
h1 + h3,
h1 + .h3,
h3 > h1 + .collapser__header,
h1 + h4,
h1 + .h4,
h1 + .collapser__header.h4,
.h1 + h2,
.h1 + .h2,
h2 > .h1 + .collapser__header,
.h1 + .table caption,
.h1 + h3,
.h1 + .h3,
h3 > .h1 + .collapser__header,
.h1 + h4,
.h1 + .h4 {
  margin-top: 0;
}

h2 + h3,
h2 + .h3,
h3 > h2 + .collapser__header,
h2 + h4,
h2 + .h4,
h2 + .collapser__header.h4,
.h2 + h3,
h2 > .collapser__header + h3,
.h2 + .h3,
h2 > .collapser__header + .h3,
h3 > .h2 + .collapser__header,
.h2 + h4,
h2 > .collapser__header + h4,
.h2 + .h4,
h2 > .collapser__header + .h4 {
  margin-top: 0;
}

h3 + h4,
h3 + .h4,
h3 + .collapser__header.h4,
.h3 + h4,
h3 > .collapser__header + h4,
.h3 + .h4,
h3 > .collapser__header + .h4 {
  margin-top: 0;
}

p,
ol,
ul {
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  max-width: 47rem;
}

a:focus {
  color: #72728a;
  box-shadow: 0;
}

.hyphens {
  -ms-hyphens: auto !important;
  -webkit-hyphens: auto !important;
  hyphens: auto !important;
}

code {
  hyphens: none;
}

p,
li,
table {
  color: #434363;
}

.bg-graa {
  background-color: #e5e5e5;
}

.text-graa {
  color: #e5e5e5;
}

.bg-sand {
  background-color: #d1c5c3;
}

.text-sand {
  color: #d1c5c3;
}

.bg-skatdk {
  background-color: #2e99d9;
}

.text-skatdk {
  color: #2e99d9;
}

.bg-ufst {
  background-color: #1c69b9;
}

.text-ufst {
  color: #1c69b9;
}

.bg-adst {
  background-color: #aac800;
}

.text-adst {
  color: #aac800;
}

.bg-motorst {
  background-color: #008139;
}

.text-motorst {
  color: #008139;
}

.bg-toldst {
  background-color: #ffbb16;
}

.text-toldst {
  color: #ffbb16;
}

.bg-vurdst {
  background-color: #ff620c;
}

.text-vurdst {
  color: #ff620c;
}

.bg-gaeldst {
  background-color: #df0515;
}

.text-gaeldst {
  color: #df0515;
}

.bg-sktst {
  background-color: #aecbda;
}

.text-sktst {
  color: #aecbda;
}

.bg-skatdk.bg-hover, .bg-skatdk.bg-focus {
  transition: background-color 0.3s;
}
.bg-skatdk.bg-hover:hover, .bg-skatdk.bg-focus:focus {
  background-color: #77a5d5;
}

.btn {
  min-width: 5rem;
  text-decoration: none;
  line-height: 0.6;
}
.btn.disabled, .btn:disabled {
  cursor: not-allowed;
  border-color: #72728a;
  background-color: #72728a !important;
}
.btn.disabled:hover, .btn.disabled:focus, .btn:disabled:hover, .btn:disabled:focus {
  outline: none;
  box-shadow: none;
  background-color: #72728a !important;
}
.btn.btn-sm, .btn-group-sm > .btn {
  min-width: auto;
  line-height: 1;
  padding: 0.5rem;
}

.input-group-prepend .btn,
.input-group-append .btn {
  padding: 0;
}

.form-control:disabled + .input-group-append > span,
.form-control[readonly] + .input-group-append > span {
  background-color: #e5e5e5;
}

.btn-primary:hover, .btn-primary:focus {
  outline: solid 1px white;
  box-shadow: 0 0 0 0.3rem #72728a;
  background-color: #434363;
}

.alt-btn {
  display: inline-flex;
  color: #434363;
  align-items: center;
  text-decoration: none;
  border: none;
  background: none;
  padding: 0;
}
.alt-btn.disabled, .alt-btn:disabled {
  cursor: not-allowed;
  color: #72728a;
}
.alt-btn.disabled::before, .alt-btn.disabled::after, .alt-btn:disabled::before, .alt-btn:disabled::after {
  background-color: #72728a;
}
.alt-btn.alt-btn--inverted {
  color: white;
}
.alt-btn:not(:disabled):hover, .alt-btn:not(:disabled):focus {
  text-decoration: underline;
}
.alt-btn:focus {
  box-shadow: 0 0 0 0.2rem #72728a;
  outline: none;
}
.alt-btn + .alt-btn {
  margin-left: 1.25em;
}
.alt-btn::before, .alt-btn::after {
  font-family: "dap-ikon-font";
  color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  background-color: #14143c;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
}
.alt-btn.alt-btn--green {
  color: #008139;
}
.alt-btn.alt-btn--green::before, .alt-btn.alt-btn--green::after {
  background-color: #008139;
}
.alt-btn.alt-btn--inverted::before, .alt-btn.alt-btn--inverted::after {
  color: #14143c;
  background-color: white;
}
.alt-btn.alt-btn--inverted::before:hover, .alt-btn.alt-btn--inverted::after:hover {
  color: white;
}
.alt-btn.alt-btn--inverted:hover::before, .alt-btn.alt-btn--inverted:focus::before {
  color: #14143c;
  background-color: white;
}
.alt-btn:not(:empty)::before {
  margin-right: 0.5rem;
}
.alt-btn.alt-btn-globus::before {
  content: "\e984";
}
.alt-btn.alt-btn-globus.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-globus.alt-btn--flipped::after {
  content: "\e984";
}
.alt-btn.alt-btn-se::before {
  content: "\e983";
}
.alt-btn.alt-btn-se.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-se.alt-btn--flipped::after {
  content: "\e983";
}
.alt-btn.alt-btn-skift-kunde::before {
  content: "\e982";
}
.alt-btn.alt-btn-skift-kunde.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-skift-kunde.alt-btn--flipped::after {
  content: "\e982";
}
.alt-btn.alt-btn-start-forfra::before {
  content: "\e981";
}
.alt-btn.alt-btn-start-forfra.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-start-forfra.alt-btn--flipped::after {
  content: "\e981";
}
.alt-btn.alt-btn-nulindberet::before {
  content: "\e97f";
}
.alt-btn.alt-btn-nulindberet.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-nulindberet.alt-btn--flipped::after {
  content: "\e97f";
}
.alt-btn.alt-btn-kopier::before {
  content: "\e980";
}
.alt-btn.alt-btn-kopier.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-kopier.alt-btn--flipped::after {
  content: "\e980";
}
.alt-btn.alt-btn-afgifter::before {
  content: "\e900";
}
.alt-btn.alt-btn-afgifter.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-afgifter.alt-btn--flipped::after {
  content: "\e900";
}
.alt-btn.alt-btn-afloeb-draen::before {
  content: "\e92e";
}
.alt-btn.alt-btn-afloeb-draen.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-afloeb-draen.alt-btn--flipped::after {
  content: "\e92e";
}
.alt-btn.alt-btn-afskrivning::before {
  content: "\e92f";
}
.alt-btn.alt-btn-afskrivning.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-afskrivning.alt-btn--flipped::after {
  content: "\e92f";
}
.alt-btn.alt-btn-aktier::before {
  content: "\e901";
}
.alt-btn.alt-btn-aktier.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-aktier.alt-btn--flipped::after {
  content: "\e901";
}
.alt-btn.alt-btn-ansatte-og-loen::before {
  content: "\e902";
}
.alt-btn.alt-btn-ansatte-og-loen.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-ansatte-og-loen.alt-btn--flipped::after {
  content: "\e902";
}
.alt-btn.alt-btn-arrow-left::before {
  content: "\e97b";
}
.alt-btn.alt-btn-arrow-left.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-arrow-left.alt-btn--flipped::after {
  content: "\e97b";
}
.alt-btn.alt-btn-arrow-right::before {
  content: "\e903";
}
.alt-btn.alt-btn-arrow-right.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-arrow-right.alt-btn--flipped::after {
  content: "\e903";
}
.alt-btn.alt-btn-asbest-pcb-bly::before {
  content: "\e930";
}
.alt-btn.alt-btn-asbest-pcb-bly.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-asbest-pcb-bly.alt-btn--flipped::after {
  content: "\e930";
}
.alt-btn.alt-btn-beregn::before {
  content: "\e931";
}
.alt-btn.alt-btn-beregn.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-beregn.alt-btn--flipped::after {
  content: "\e931";
}
.alt-btn.alt-btn-betalings-id::before {
  content: "\e97a";
}
.alt-btn.alt-btn-betalings-id.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-betalings-id.alt-btn--flipped::after {
  content: "\e97a";
}
.alt-btn.alt-btn-bil::before {
  content: "\e904";
}
.alt-btn.alt-btn-bil.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-bil.alt-btn--flipped::after {
  content: "\e904";
}
.alt-btn.alt-btn-boede::before {
  content: "\e932";
}
.alt-btn.alt-btn-boede.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-boede.alt-btn--flipped::after {
  content: "\e932";
}
.alt-btn.alt-btn-boernepasning::before {
  content: "\e933";
}
.alt-btn.alt-btn-boernepasning.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-boernepasning.alt-btn--flipped::after {
  content: "\e933";
}
.alt-btn.alt-btn-borger::before {
  content: "\e905";
}
.alt-btn.alt-btn-borger.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-borger.alt-btn--flipped::after {
  content: "\e905";
}
.alt-btn.alt-btn-braendeovne::before {
  content: "\e934";
}
.alt-btn.alt-btn-braendeovne.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-braendeovne.alt-btn--flipped::after {
  content: "\e934";
}
.alt-btn.alt-btn-cykel::before {
  content: "\e917";
}
.alt-btn.alt-btn-cykel.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-cykel.alt-btn--flipped::after {
  content: "\e917";
}
.alt-btn.alt-btn-dagsbevis::before {
  content: "\e91d";
}
.alt-btn.alt-btn-dagsbevis.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-dagsbevis.alt-btn--flipped::after {
  content: "\e91d";
}
.alt-btn.alt-btn-dk-flag::before {
  content: "\e918";
}
.alt-btn.alt-btn-dk-flag.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-dk-flag.alt-btn--flipped::after {
  content: "\e918";
}
.alt-btn.alt-btn-doedsfald::before {
  content: "\e935";
}
.alt-btn.alt-btn-doedsfald.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-doedsfald.alt-btn--flipped::after {
  content: "\e935";
}
.alt-btn.alt-btn-eindkomst::before {
  content: "\e91e";
}
.alt-btn.alt-btn-eindkomst.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-eindkomst.alt-btn--flipped::after {
  content: "\e91e";
}
.alt-btn.alt-btn-ejendom::before {
  content: "\e906";
}
.alt-btn.alt-btn-ejendom.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-ejendom.alt-btn--flipped::after {
  content: "\e906";
}
.alt-btn.alt-btn-ejendomsvurdering::before {
  content: "\e936";
}
.alt-btn.alt-btn-ejendomsvurdering.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-ejendomsvurdering.alt-btn--flipped::after {
  content: "\e936";
}
.alt-btn.alt-btn-ekapital::before {
  content: "\e919";
}
.alt-btn.alt-btn-ekapital.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-ekapital.alt-btn--flipped::after {
  content: "\e919";
}
.alt-btn.alt-btn-erhverv::before {
  content: "\e907";
}
.alt-btn.alt-btn-erhverv.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-erhverv.alt-btn--flipped::after {
  content: "\e907";
}
.alt-btn.alt-btn-excel::before {
  content: "\e937";
}
.alt-btn.alt-btn-excel.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-excel.alt-btn--flipped::after {
  content: "\e937";
}
.alt-btn.alt-btn-facebook::before {
  content: "\e91f";
}
.alt-btn.alt-btn-facebook.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-facebook.alt-btn--flipped::after {
  content: "\e91f";
}
.alt-btn.alt-btn-fjernvarmeunits::before {
  content: "\e938";
}
.alt-btn.alt-btn-fjernvarmeunits.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-fjernvarmeunits.alt-btn--flipped::after {
  content: "\e938";
}
.alt-btn.alt-btn-flytning::before {
  content: "\e939";
}
.alt-btn.alt-btn-flytning.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-flytning.alt-btn--flipped::after {
  content: "\e939";
}
.alt-btn.alt-btn-foreninger::before {
  content: "\e908";
}
.alt-btn.alt-btn-foreninger.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-foreninger.alt-btn--flipped::after {
  content: "\e908";
}
.alt-btn.alt-btn-formue::before {
  content: "\e93a";
}
.alt-btn.alt-btn-formue.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-formue.alt-btn--flipped::after {
  content: "\e93a";
}
.alt-btn.alt-btn-forskning::before {
  content: "\e920";
}
.alt-btn.alt-btn-forskning.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-forskning.alt-btn--flipped::after {
  content: "\e920";
}
.alt-btn.alt-btn-fradrag::before {
  content: "\e909";
}
.alt-btn.alt-btn-fradrag.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-fradrag.alt-btn--flipped::after {
  content: "\e909";
}
.alt-btn.alt-btn-gaeld::before {
  content: "\e972";
}
.alt-btn.alt-btn-gaeld.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-gaeld.alt-btn--flipped::after {
  content: "\e972";
}
.alt-btn.alt-btn-gaver::before {
  content: "\e90a";
}
.alt-btn.alt-btn-gaver.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-gaver.alt-btn--flipped::after {
  content: "\e90a";
}
.alt-btn.alt-btn-gem::before {
  content: "\e978";
}
.alt-btn.alt-btn-gem.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-gem.alt-btn--flipped::after {
  content: "\e978";
}
.alt-btn.alt-btn-glasdoere::before {
  content: "\e93b";
}
.alt-btn.alt-btn-glasdoere.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-glasdoere.alt-btn--flipped::after {
  content: "\e93b";
}
.alt-btn.alt-btn-godt-fra-start::before {
  content: "\e973";
}
.alt-btn.alt-btn-godt-fra-start.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-godt-fra-start.alt-btn--flipped::after {
  content: "\e973";
}
.alt-btn.alt-btn-havearbejde::before {
  content: "\e93c";
}
.alt-btn.alt-btn-havearbejde.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-havearbejde.alt-btn--flipped::after {
  content: "\e93c";
}
.alt-btn.alt-btn-henvendelser::before {
  content: "\e93d";
}
.alt-btn.alt-btn-henvendelser.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-henvendelser.alt-btn--flipped::after {
  content: "\e93d";
}
.alt-btn.alt-btn-hjaelp-til-tastselv::before {
  content: "\e90b";
}
.alt-btn.alt-btn-hjaelp-til-tastselv.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-hjaelp-til-tastselv.alt-btn--flipped::after {
  content: "\e90b";
}
.alt-btn.alt-btn-hjaelp::before {
  content: "\e93e";
}
.alt-btn.alt-btn-hjaelp.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-hjaelp.alt-btn--flipped::after {
  content: "\e93e";
}
.alt-btn.alt-btn-haandvaerkerfradrag::before {
  content: "\e921";
}
.alt-btn.alt-btn-haandvaerkerfradrag.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-haandvaerkerfradrag.alt-btn--flipped::after {
  content: "\e921";
}
.alt-btn.alt-btn-indenfor-eu::before {
  content: "\e93f";
}
.alt-btn.alt-btn-indenfor-eu.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-indenfor-eu.alt-btn--flipped::after {
  content: "\e93f";
}
.alt-btn.alt-btn-indkomst-og-skat::before {
  content: "\e90c";
}
.alt-btn.alt-btn-indkomst-og-skat.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-indkomst-og-skat.alt-btn--flipped::after {
  content: "\e90c";
}
.alt-btn.alt-btn-indstillinger::before {
  content: "\e940";
}
.alt-btn.alt-btn-indstillinger.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-indstillinger.alt-btn--flipped::after {
  content: "\e940";
}
.alt-btn.alt-btn-information::before {
  content: "\e941";
}
.alt-btn.alt-btn-information.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-information.alt-btn--flipped::after {
  content: "\e941";
}
.alt-btn.alt-btn-instagram::before {
  content: "\e922";
}
.alt-btn.alt-btn-instagram.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-instagram.alt-btn--flipped::after {
  content: "\e922";
}
.alt-btn.alt-btn-installation-af-varmepumper::before {
  content: "\e942";
}
.alt-btn.alt-btn-installation-af-varmepumper.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-installation-af-varmepumper.alt-btn--flipped::after {
  content: "\e942";
}
.alt-btn.alt-btn-integrer::before {
  content: "\e943";
}
.alt-btn.alt-btn-integrer.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-integrer.alt-btn--flipped::after {
  content: "\e943";
}
.alt-btn.alt-btn-intelligent-styring::before {
  content: "\e944";
}
.alt-btn.alt-btn-intelligent-styring.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-intelligent-styring.alt-btn--flipped::after {
  content: "\e944";
}
.alt-btn.alt-btn-isolering-af-gulv::before {
  content: "\e945";
}
.alt-btn.alt-btn-isolering-af-gulv.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-isolering-af-gulv.alt-btn--flipped::after {
  content: "\e945";
}
.alt-btn.alt-btn-isolering-af-tag::before {
  content: "\e946";
}
.alt-btn.alt-btn-isolering-af-tag.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-isolering-af-tag.alt-btn--flipped::after {
  content: "\e946";
}
.alt-btn.alt-btn-isolering-af-ydervaegge::before {
  content: "\e947";
}
.alt-btn.alt-btn-isolering-af-ydervaegge.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-isolering-af-ydervaegge.alt-btn--flipped::after {
  content: "\e947";
}
.alt-btn.alt-btn-ivaerksaetter::before {
  content: "\e90d";
}
.alt-btn.alt-btn-ivaerksaetter.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-ivaerksaetter.alt-btn--flipped::after {
  content: "\e90d";
}
.alt-btn.alt-btn-job::before {
  content: "\e948";
}
.alt-btn.alt-btn-job.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-job.alt-btn--flipped::after {
  content: "\e948";
}
.alt-btn.alt-btn-kalender::before {
  content: "\e949";
}
.alt-btn.alt-btn-kalender.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-kalender.alt-btn--flipped::after {
  content: "\e949";
}
.alt-btn.alt-btn-kontakt::before {
  content: "\e94a";
}
.alt-btn.alt-btn-kontakt.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-kontakt.alt-btn--flipped::after {
  content: "\e94a";
}
.alt-btn.alt-btn-kontekstuel-lovgivning::before {
  content: "\e94b";
}
.alt-btn.alt-btn-kontekstuel-lovgivning.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-kontekstuel-lovgivning.alt-btn--flipped::after {
  content: "\e94b";
}
.alt-btn.alt-btn-kuffert::before {
  content: "\e91a";
}
.alt-btn.alt-btn-kuffert.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-kuffert.alt-btn--flipped::after {
  content: "\e91a";
}
.alt-btn.alt-btn-ladestik-til-elbiler::before {
  content: "\e94c";
}
.alt-btn.alt-btn-ladestik-til-elbiler.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-ladestik-til-elbiler.alt-btn--flipped::after {
  content: "\e94c";
}
.alt-btn.alt-btn-linkedin::before {
  content: "\e923";
}
.alt-btn.alt-btn-linkedin.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-linkedin.alt-btn--flipped::after {
  content: "\e923";
}
.alt-btn.alt-btn-loenindkomst::before {
  content: "\e94d";
}
.alt-btn.alt-btn-loenindkomst.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-loenindkomst.alt-btn--flipped::after {
  content: "\e94d";
}
.alt-btn.alt-btn-loensumsafgift::before {
  content: "\e91b";
}
.alt-btn.alt-btn-loensumsafgift.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-loensumsafgift.alt-btn--flipped::after {
  content: "\e91b";
}
.alt-btn.alt-btn-log-ind::before {
  content: "\e90e";
}
.alt-btn.alt-btn-log-ind.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-log-ind.alt-btn--flipped::after {
  content: "\e90e";
}
.alt-btn.alt-btn-log-ud::before {
  content: "\e953";
}
.alt-btn.alt-btn-log-ud.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-log-ud.alt-btn--flipped::after {
  content: "\e953";
}
.alt-btn.alt-btn-luk::before {
  content: "\e90f";
}
.alt-btn.alt-btn-luk.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-luk.alt-btn--flipped::after {
  content: "\e90f";
}
.alt-btn.alt-btn-lyt::before {
  content: "\e94e";
}
.alt-btn.alt-btn-lyt.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-lyt.alt-btn--flipped::after {
  content: "\e94e";
}
.alt-btn.alt-btn-laan::before {
  content: "\e910";
}
.alt-btn.alt-btn-laan.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-laan.alt-btn--flipped::after {
  content: "\e910";
}
.alt-btn.alt-btn-menu::before {
  content: "\e92c";
}
.alt-btn.alt-btn-menu.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-menu.alt-btn--flipped::after {
  content: "\e92c";
}
.alt-btn.alt-btn-moms::before {
  content: "\e911";
}
.alt-btn.alt-btn-moms.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-moms.alt-btn--flipped::after {
  content: "\e911";
}
.alt-btn.alt-btn-more::before {
  content: "\e91c";
}
.alt-btn.alt-btn-more.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-more.alt-btn--flipped::after {
  content: "\e91c";
}
.alt-btn.alt-btn-maalflag::before {
  content: "\e97e";
}
.alt-btn.alt-btn-maalflag.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-maalflag.alt-btn--flipped::after {
  content: "\e97e";
}
.alt-btn.alt-btn-ok::before {
  content: "\e97d";
}
.alt-btn.alt-btn-ok.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-ok.alt-btn--flipped::after {
  content: "\e97d";
}
.alt-btn.alt-btn-omregistrering::before {
  content: "\e924";
}
.alt-btn.alt-btn-omregistrering.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-omregistrering.alt-btn--flipped::after {
  content: "\e924";
}
.alt-btn.alt-btn-pdf::before {
  content: "\e94f";
}
.alt-btn.alt-btn-pdf.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-pdf.alt-btn--flipped::after {
  content: "\e94f";
}
.alt-btn.alt-btn-pension::before {
  content: "\e950";
}
.alt-btn.alt-btn-pension.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-pension.alt-btn--flipped::after {
  content: "\e950";
}
.alt-btn.alt-btn-permeable-belaegninger::before {
  content: "\e951";
}
.alt-btn.alt-btn-permeable-belaegninger.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-permeable-belaegninger.alt-btn--flipped::after {
  content: "\e951";
}
.alt-btn.alt-btn-personalegoder::before {
  content: "\e912";
}
.alt-btn.alt-btn-personalegoder.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-personalegoder.alt-btn--flipped::after {
  content: "\e912";
}
.alt-btn.alt-btn-pinterest::before {
  content: "\e925";
}
.alt-btn.alt-btn-pinterest.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-pinterest.alt-btn--flipped::after {
  content: "\e925";
}
.alt-btn.alt-btn-play::before {
  content: "\e952";
}
.alt-btn.alt-btn-play.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-play.alt-btn--flipped::after {
  content: "\e952";
}
.alt-btn.alt-btn-print::before {
  content: "\e979";
}
.alt-btn.alt-btn-print.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-print.alt-btn--flipped::after {
  content: "\e979";
}
.alt-btn.alt-btn-problem::before {
  content: "\e954";
}
.alt-btn.alt-btn-problem.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-problem.alt-btn--flipped::after {
  content: "\e954";
}
.alt-btn.alt-btn-punktafgifter::before {
  content: "\e955";
}
.alt-btn.alt-btn-punktafgifter.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-punktafgifter.alt-btn--flipped::after {
  content: "\e955";
}
.alt-btn.alt-btn-radonsikring::before {
  content: "\e956";
}
.alt-btn.alt-btn-radonsikring.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-radonsikring.alt-btn--flipped::after {
  content: "\e956";
}
.alt-btn.alt-btn-rediger::before {
  content: "\e976";
}
.alt-btn.alt-btn-rediger.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-rediger.alt-btn--flipped::after {
  content: "\e976";
}
.alt-btn.alt-btn-registreringsafgift::before {
  content: "\e957";
}
.alt-btn.alt-btn-registreringsafgift.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-registreringsafgift.alt-btn--flipped::after {
  content: "\e957";
}
.alt-btn.alt-btn-rengoering::before {
  content: "\e958";
}
.alt-btn.alt-btn-rengoering.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-rengoering.alt-btn--flipped::after {
  content: "\e958";
}
.alt-btn.alt-btn-renteudgifter-oevrige::before {
  content: "\e959";
}
.alt-btn.alt-btn-renteudgifter-oevrige.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-renteudgifter-oevrige.alt-btn--flipped::after {
  content: "\e959";
}
.alt-btn.alt-btn-raadgivere-revisorer::before {
  content: "\e95a";
}
.alt-btn.alt-btn-raadgivere-revisorer.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-raadgivere-revisorer.alt-btn--flipped::after {
  content: "\e95a";
}
.alt-btn.alt-btn-raadgivning::before {
  content: "\e95b";
}
.alt-btn.alt-btn-raadgivning.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-raadgivning.alt-btn--flipped::after {
  content: "\e95b";
}
.alt-btn.alt-btn-segment::before {
  content: "\e95c";
}
.alt-btn.alt-btn-segment.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-segment.alt-btn--flipped::after {
  content: "\e95c";
}
.alt-btn.alt-btn-send::before {
  content: "\e95d";
}
.alt-btn.alt-btn-send.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-send.alt-btn--flipped::after {
  content: "\e95d";
}
.alt-btn.alt-btn-servicedesk::before {
  content: "\e95e";
}
.alt-btn.alt-btn-servicedesk.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-servicedesk.alt-btn--flipped::after {
  content: "\e95e";
}
.alt-btn.alt-btn-skattekonto::before {
  content: "\e913";
}
.alt-btn.alt-btn-skattekonto.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-skattekonto.alt-btn--flipped::after {
  content: "\e913";
}
.alt-btn.alt-btn-skattekort::before {
  content: "\e95f";
}
.alt-btn.alt-btn-skattekort.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-skattekort.alt-btn--flipped::after {
  content: "\e95f";
}
.alt-btn.alt-btn-skatten-2015::before {
  content: "\e926";
}
.alt-btn.alt-btn-skatten-2015.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-skatten-2015.alt-btn--flipped::after {
  content: "\e926";
}
.alt-btn.alt-btn-skatten-2016::before {
  content: "\e92d";
}
.alt-btn.alt-btn-skatten-2016.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-skatten-2016.alt-btn--flipped::after {
  content: "\e92d";
}
.alt-btn.alt-btn-skatten-2017::before {
  content: "\e927";
}
.alt-btn.alt-btn-skatten-2017.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-skatten-2017.alt-btn--flipped::after {
  content: "\e927";
}
.alt-btn.alt-btn-skatten-2018::before {
  content: "\e974";
}
.alt-btn.alt-btn-skatten-2018.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-skatten-2018.alt-btn--flipped::after {
  content: "\e974";
}
.alt-btn.alt-btn-skatten-2019::before {
  content: "\e975";
}
.alt-btn.alt-btn-skatten-2019.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-skatten-2019.alt-btn--flipped::after {
  content: "\e975";
}
.alt-btn.alt-btn-skorsten::before {
  content: "\e960";
}
.alt-btn.alt-btn-skorsten.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-skorsten.alt-btn--flipped::after {
  content: "\e960";
}
.alt-btn.alt-btn-slet::before {
  content: "\e977";
}
.alt-btn.alt-btn-slet.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-slet.alt-btn--flipped::after {
  content: "\e977";
}
.alt-btn.alt-btn-soeg::before {
  content: "\e914";
}
.alt-btn.alt-btn-soeg.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-soeg.alt-btn--flipped::after {
  content: "\e914";
}
.alt-btn.alt-btn-solafskaermning::before {
  content: "\e961";
}
.alt-btn.alt-btn-solafskaermning.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-solafskaermning.alt-btn--flipped::after {
  content: "\e961";
}
.alt-btn.alt-btn-starting-a-business::before {
  content: "\e962";
}
.alt-btn.alt-btn-starting-a-business.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-starting-a-business.alt-btn--flipped::after {
  content: "\e962";
}
.alt-btn.alt-btn-su::before {
  content: "\e963";
}
.alt-btn.alt-btn-su.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-su.alt-btn--flipped::after {
  content: "\e963";
}
.alt-btn.alt-btn-tilfoej::before {
  content: "\e97c";
}
.alt-btn.alt-btn-tilfoej.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-tilfoej.alt-btn--flipped::after {
  content: "\e97c";
}
.alt-btn.alt-btn-tilslutning-til-bredbaand::before {
  content: "\e964";
}
.alt-btn.alt-btn-tilslutning-til-bredbaand.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-tilslutning-til-bredbaand.alt-btn--flipped::after {
  content: "\e964";
}
.alt-btn.alt-btn-told::before {
  content: "\e915";
}
.alt-btn.alt-btn-told.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-told.alt-btn--flipped::after {
  content: "\e915";
}
.alt-btn.alt-btn-transparens::before {
  content: "\e965";
}
.alt-btn.alt-btn-transparens.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-transparens.alt-btn--flipped::after {
  content: "\e965";
}
.alt-btn.alt-btn-transport::before {
  content: "\e966";
}
.alt-btn.alt-btn-transport.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-transport.alt-btn--flipped::after {
  content: "\e966";
}
.alt-btn.alt-btn-twitter::before {
  content: "\e928";
}
.alt-btn.alt-btn-twitter.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-twitter.alt-btn--flipped::after {
  content: "\e928";
}
.alt-btn.alt-btn-udland::before {
  content: "\e929";
}
.alt-btn.alt-btn-udland.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-udland.alt-btn--flipped::after {
  content: "\e929";
}
.alt-btn.alt-btn-udvendigt-malerarbejde::before {
  content: "\e967";
}
.alt-btn.alt-btn-udvendigt-malerarbejde.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-udvendigt-malerarbejde.alt-btn--flipped::after {
  content: "\e967";
}
.alt-btn.alt-btn-udvidet-indberetning::before {
  content: "\e968";
}
.alt-btn.alt-btn-udvidet-indberetning.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-udvidet-indberetning.alt-btn--flipped::after {
  content: "\e968";
}
.alt-btn.alt-btn-underholdsbidrag::before {
  content: "\e916";
}
.alt-btn.alt-btn-underholdsbidrag.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-underholdsbidrag.alt-btn--flipped::after {
  content: "\e916";
}
.alt-btn.alt-btn-unge::before {
  content: "\e969";
}
.alt-btn.alt-btn-unge.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-unge.alt-btn--flipped::after {
  content: "\e969";
}
.alt-btn.alt-btn-upload::before {
  content: "\e96a";
}
.alt-btn.alt-btn-upload.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-upload.alt-btn--flipped::after {
  content: "\e96a";
}
.alt-btn.alt-btn-varmeanlaeg::before {
  content: "\e96b";
}
.alt-btn.alt-btn-varmeanlaeg.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-varmeanlaeg.alt-btn--flipped::after {
  content: "\e96b";
}
.alt-btn.alt-btn-ventilation::before {
  content: "\e96c";
}
.alt-btn.alt-btn-ventilation.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-ventilation.alt-btn--flipped::after {
  content: "\e96c";
}
.alt-btn.alt-btn-vindmoeller::before {
  content: "\e96d";
}
.alt-btn.alt-btn-vindmoeller.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-vindmoeller.alt-btn--flipped::after {
  content: "\e96d";
}
.alt-btn.alt-btn-vinduespudsning::before {
  content: "\e96e";
}
.alt-btn.alt-btn-vinduespudsning.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-vinduespudsning.alt-btn--flipped::after {
  content: "\e96e";
}
.alt-btn.alt-btn-virksomhed::before {
  content: "\e96f";
}
.alt-btn.alt-btn-virksomhed.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-virksomhed.alt-btn--flipped::after {
  content: "\e96f";
}
.alt-btn.alt-btn-virksomhedsregistrering::before {
  content: "\e92a";
}
.alt-btn.alt-btn-virksomhedsregistrering.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-virksomhedsregistrering.alt-btn--flipped::after {
  content: "\e92a";
}
.alt-btn.alt-btn-yderdoere::before {
  content: "\e970";
}
.alt-btn.alt-btn-yderdoere.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-yderdoere.alt-btn--flipped::after {
  content: "\e970";
}
.alt-btn.alt-btn-youtube::before {
  content: "\e92b";
}
.alt-btn.alt-btn-youtube.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-youtube.alt-btn--flipped::after {
  content: "\e92b";
}
.alt-btn.alt-btn-aarsopgoerelsen::before {
  content: "\e971";
}
.alt-btn.alt-btn-aarsopgoerelsen.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-aarsopgoerelsen.alt-btn--flipped::after {
  content: "\e971";
}
.alt-btn.alt-btn-download:before {
  content: "\e96a";
  transform: rotate(0.5turn);
}
.alt-btn.alt-btn-download:before.alt-btn--flipped::before {
  content: none;
}
.alt-btn.alt-btn-download:before.alt-btn--flipped::after {
  content: "\e96a";
  transform: rotate(0.5turn);
}
.alt-btn.alt-btn--flipped::after {
  margin-left: 0.5rem;
}
.alt-btn.alt-btn--flipped:hover::after, .alt-btn.alt-btn--flipped:focus::after {
  background-color: #72728a;
  color: white;
}
.alt-btn:hover:not(:disabled), .alt-btn:focus:not(:disabled) {
  color: #72728a;
}
.alt-btn:hover:not(:disabled)::before, .alt-btn:focus:not(:disabled)::before {
  background-color: #72728a;
  color: white;
}
.alt-btn:hover.alt-btn--green:not(:disabled)::before, .alt-btn:focus.alt-btn--green:not(:disabled)::before {
  background-color: #66b388;
}

.alt-btn.alt-btn-spinner {
  position: relative;
}
.alt-btn.alt-btn-spinner::before {
  content: " ";
}
.alt-btn.alt-btn-spinner::after {
  content: "";
  position: absolute;
  left: 0px;
  top: calc(50% - 1rem);
  border-radius: 0;
  background-color: transparent;
  background-size: 19.2px 19.2px;
  background-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0D%0A%3Csvg%20version%3D%221.1%22%20%0D%0A%09xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20%0D%0A%09xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20width%3D%2238px%22%20height%3D%2238px%22%20viewBox%3D%220%200%2038%2038%22%20xml%3Aspace%3D%22preserve%22%3E%0D%0A%09%3Cpath%20opacity%3D%220.5%22%20fill%3D%22%23FFFFFF%22%20d%3D%22M19%2C3.1c8.8%2C0%2C15.9%2C7.1%2C15.9%2C15.9S27.8%2C34.9%2C19%2C34.9c-4.3%2C0-8.3-1.7-11.1-4.5l-2.1%2C2.2%0D%0A%09C9.2%2C35.9%2C13.8%2C38%2C19%2C38c10.5%2C0%2C19-8.5%2C19-19S29.5%2C0%2C19%2C0l0%2C0C13.8%2C0%2C9.2%2C2.1%2C5.7%2C5.4l2.2%2C2.2C10.8%2C4.8%2C14.7%2C3.1%2C19%2C3.1%22%2F%3E%0D%0A%09%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M7.9%2C30.4c-3-2.9-4.8-6.9-4.8-11.4c0-4.5%2C1.8-8.5%2C4.8-11.4L5.7%2C5.4C2.2%2C8.9%2C0%2C13.7%2C0%2C19%0D%0A%09c0%2C5.3%2C2.2%2C10.2%2C5.7%2C13.6L7.9%2C30.4z%22%2F%3E%0D%0A%3C%2Fsvg%3E%0D%0A");
  animation: rotating 1s linear 0s infinite;
  transition: opacity 0.3s ease-in-out;
}

.help-icon {
  position: relative;
  display: inline-block;
  text-align: center;
  border: none;
  background-color: white;
  margin: 0 0 0.3rem 0.3rem;
  padding: 0;
  width: 1.5em;
  height: 1.5em;
  font-weight: bold;
  border-radius: 50%;
}
.help-icon:focus {
  box-shadow: none;
}
.help-icon, .help-icon:hover {
  text-decoration: none;
}
.help-icon::before {
  content: "";
  border: solid 1px #14143c;
  border-radius: 50%;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.help-icon:hover,
.help-icon .hover, .help-icon:focus, .help-icon.focus {
  outline: 0;
}
.help-icon:hover::before,
.help-icon .hover::before, .help-icon:focus::before, .help-icon.focus::before {
  box-shadow: 0 0 0 2px white, 0 0 0 6px #72728a;
}

.btn.btn-profile {
  border: solid 1px #14143c;
  display: inline-flex;
  align-items: center;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
@media (min-width: 576px) {
  .btn.btn-profile {
    border-right: none;
    border-top: none;
  }
}
.btn.btn-profile:hover {
  color: white;
  background: #72728a;
  box-shadow: none;
  border-color: transparent;
}
.btn.btn-profile:focus {
  box-shadow: 0 0 0 0.3rem #72728a inset;
  border: none;
}
.btn.btn-profile:disabled, .btn.btn-profile.disabled {
  border-color: #e5e5e5;
  border-bottom: none;
  pointer-events: all;
}

.action-btn {
  padding: 1.2rem 1.5rem 1.2rem 5.5rem;
  display: inline-block;
  flex-direction: row-reverse;
  text-decoration: none;
  transition: background-color 0.3s;
  border: none;
  position: relative;
}
.action-btn:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 6px #72728a;
}
.action-btn:hover, .action-btn:focus {
  text-decoration: none;
  color: #14143c;
  outline: none;
}
.action-btn:hover::before, .action-btn:focus::before {
  transform: translateX(0.4rem);
}
.action-btn::before, .action-btn::after {
  position: absolute;
  top: 0;
  height: 100%;
}
.action-btn::before {
  font-family: "dap-ikon-font";
  left: 0;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s;
}
.action-btn::after {
  content: "";
  left: 4rem;
  width: 1px;
  opacity: 0.3;
  border-right: solid 1px;
}
.action-btn-logon {
  background-color: #2e99d9;
}
.action-btn-logon:hover, .action-btn-logon:focus {
  background-color: #77a5d5;
}
.action-btn-logon:before {
  content: "\e90e";
}
.action-btn-logon:after {
  border-color: #14143c;
}
.action-btn-arrow {
  color: white;
  background-color: #14143c;
}
.action-btn-arrow:hover, .action-btn-arrow:focus {
  color: white;
  background-color: #434363;
}
.action-btn-arrow:before {
  content: "\e903";
  font-size: 0.75rem;
}
.action-btn-arrow:after {
  border-color: white;
}

.transport-box {
  color: #14143c;
  text-decoration: none;
  border-top: 1px solid #e5e5e5;
  border-right: 1px solid #ffffff;
  border-bottom: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
  background: white;
  margin: 0;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.transport-box:hover {
  border: 1px solid #14143c;
  color: #14143c;
  text-decoration: none;
}
.transport-box:focus {
  z-index: 10;
}
.transport-box:focus p {
  color: #72728a;
}
.transport-box h2 {
  flex-grow: 1;
  font-weight: 600;
  margin: 0 0 1rem 0;
}
.transport-box p {
  margin-bottom: 0;
  position: relative;
  display: flex;
  color: #14143c;
}
.transport-box p:before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  flex-shrink: 0;
  margin-right: 1rem;
  margin-top: 0.5rem;
  border-style: solid;
  border-width: 2px 2px 0 0;
  transform: rotate(45deg);
  transition: all 0.3s;
}
.transport-box:focus p::before, .transport-box:hover p::before {
  transform: translateX(0.4rem) rotate(45deg);
}
@media (min-width: 768px) {
  .transport-box:nth-child(odd) {
    border-right: 1px solid #e5e5e5;
  }
  .transport-box:last-child {
    border-right: 1px solid #ffffff;
  }
  .transport-box.transport-box--column {
    border-right-color: white;
    display: block;
  }
  .transport-box:hover {
    border: 1px solid #14143c;
  }
}
@media (min-width: 992px) {
  .transport-box {
    border-right: 1px solid #e5e5e5;
  }
  .transport-box:nth-child(3n+3) {
    border-right: 1px solid #ffffff;
  }
  .transport-box.transport-box--column {
    border-right-color: white;
  }
  .transport-box:hover {
    border: 1px solid #14143c;
  }
}

.breadcrumb {
  background-color: #ffffff;
  margin-bottom: 0;
}
@media (max-width: 767.98px) {
  .breadcrumb {
    padding: 24px;
  }
}
.breadcrumb-item {
  display: flex;
  align-items: center;
}
.breadcrumb-item a {
  text-decoration: none;
}
.breadcrumb-item a:focus {
  color: #434363;
}
.breadcrumb-item:not(:last-child):after {
  content: "";
  width: 0.5rem;
  height: 0.5rem;
  border: solid #434363;
  border-width: 2px 2px 0 0;
  margin: 0 1rem;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

header {
  margin-bottom: 1.5rem;
}

.btn-other {
  height: 42px;
  margin: auto 1rem;
  padding: 0;
  display: flex;
  align-items: center;
  text-decoration: none !important;
}
.btn-other.inactive {
  border-bottom: 2px transparent solid;
}
.btn-other.active, .btn-other:hover {
  border-bottom: 2px white solid;
}
.btn-other:focus {
  outline: none;
  box-shadow: 0 0 0 0.3rem #72728a inset;
}

.btn-logon {
  max-width: 15.3rem;
  background: #14143c;
  display: inline-block;
  color: #14143c !important;
  text-decoration: none;
  border-top: 1px solid #14143c;
  border-left: 1px solid #14143c;
  border-right: 1px solid #14143c;
  border-bottom: 1px solid #14143c;
  position: relative;
}
.btn-logon.active {
  border-bottom: none;
}
.btn-logon:hover {
  color: #14143c;
}
.btn-logon:focus {
  outline: solid 1px white;
  box-shadow: 0 0 0 0.3rem #72728a inset;
  color: #14143c;
}

@media (max-width: 767.98px) {
  .btn-logon {
    height: 44px;
    border: 0 solid transparent;
    margin-right: 0;
  }
}
.header-element {
  display: flex;
  color: white;
  transition: box-shadow ease-in-out 0.3s, background-color ease-in-out 0.3s;
  align-items: center;
  text-decoration: none;
}
@media (max-width: 991.98px) {
  .header-element {
    height: 44px;
  }
}
@media (min-width: 768px) {
  .header-element {
    height: 72px;
  }
}
.header-element.active {
  border-bottom: 2px white solid;
}
.header-element:hover {
  color: white;
}
.header-element:focus {
  outline: solid 1px white;
  box-shadow: 0 0 0 0.3rem #72728a inset;
  background-color: #14143c;
  color: white;
}
.header-element:hover {
  text-decoration: none;
}
.header-element.skatdklogo {
  width: 190px;
  background-image: url(../images/skatdk-logo-white-full.svg);
  background-repeat: no-repeat;
  background-position: center;
}
@media (max-width: 767.98px) {
  .header-element.skatdklogo {
    width: 26px;
    background-image: url(../images/skatdk-logo-white-mobile.svg);
    background-size: 28px;
  }
}
.header-element.bg-skatdk {
  color: black;
}
.header-element.bg-skatdk:hover, .header-element.bg-skatdk:focus {
  background-color: #72728a;
}

.sr-only-focusable.sr-only-focusable-center {
  position: absolute;
  left: 95px;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #14143c;
}

.with-caret::after {
  content: "";
  border-top: 0.5196152424em solid;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent;
  border-bottom: 0;
  display: block;
  transition: transform ease-in-out 0.2s;
  margin-left: 10px;
  position: absolute;
  right: 14px;
  top: 22px;
}
.with-caret[aria-expanded=true]::after {
  transform: rotate(0.5turn);
}

@media (max-width: 767.98px) {
  .with-caret::after {
    display: none;
  }
}
.infocontent {
  position: absolute;
  max-width: 100vw;
  background-color: white;
  border-left: 1px solid #14143c;
  border-right: 1px solid #14143c;
  border-bottom: 1px solid #14143c;
  padding: 12px;
}
[aria-expanded=false] + .infocontent {
  display: none;
}

.skatdk-header {
  border-bottom: 6px solid #2e99d9;
}

header .styrelsesheader {
  background-color: #14143c;
  position: relative;
  color: white;
  height: 5rem;
  display: flex;
}
header .styrelsesheader__right {
  margin-left: auto;
  position: relative;
  overflow: hidden;
}
header .styrelsesheader__right.showinfo {
  overflow: visible;
}
header .styrelsesheader__right.showinfo .styrelsesheader__infotoggler:after {
  transform: rotate(0.5turn);
}
header .styrelsesheader__logo {
  padding: 1rem 0.5rem;
  height: 5rem;
}
header .styrelsesheader__logo.skatdk {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
header .styrelsesheader__infotoggler {
  border: none;
  background-color: #2e99d9;
  height: 100%;
  margin-left: auto;
  text-align: left;
  padding: 0 1rem;
  color: inherit;
  display: flex;
  align-items: center;
}
header .styrelsesheader__infotoggler:after {
  content: "";
  display: block;
  transition: transform ease-in-out 0.2s;
  border-top: 0.5196152424em solid;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent;
  border-bottom: 0;
  margin-left: 1rem;
}
header .styrelsesheader .styrelsesheader__info {
  padding: 1.5rem 1rem;
  text-align: left;
  background-color: #2e99d9;
}

.ufst header .styrelsesheader__right {
  color: white;
}
.ufst header .styrelsesheader__right p {
  color: white;
}

.adst header .styrelsesheader__right {
  color: black;
}
.adst header .styrelsesheader__right p {
  color: black;
}

.motorst header .styrelsesheader__right {
  color: white;
}
.motorst header .styrelsesheader__right p {
  color: white;
}

.toldst header .styrelsesheader__right {
  color: black;
}
.toldst header .styrelsesheader__right p {
  color: black;
}

.vurdst header .styrelsesheader__right {
  color: black;
}
.vurdst header .styrelsesheader__right p {
  color: black;
}

.gaeldst header .styrelsesheader__right {
  color: white;
}
.gaeldst header .styrelsesheader__right p {
  color: white;
}

.sktst header .styrelsesheader__right {
  color: black;
}
.sktst header .styrelsesheader__right p {
  color: black;
}

footer {
  background-color: #14143c;
  color: white;
}
footer .h1,
footer .h2,
footer h2 > .collapser__header,
footer .h3,
footer h3 > .collapser__header {
  color: #89899d;
}
footer a.facebook, footer a.twitter, footer a.youtube {
  position: relative;
  padding: 0 1rem 0 2rem;
  text-decoration: none;
  display: inline-block;
}
footer a.facebook:hover::before, footer a.facebook:focus::before, footer a.twitter:hover::before, footer a.twitter:focus::before, footer a.youtube:hover::before, footer a.youtube:focus::before {
  left: 0.7rem;
}
footer a.facebook::before, footer a.twitter::before, footer a.youtube::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 4px;
  margin-top: -1px;
  width: 18px;
  height: 18px;
  transition: all 0.3s;
}
footer a.facebook::before {
  background: url(../images/ikoner/ikon-facebook--hvid.svg) 18px 18px/contain;
}
footer a.twitter::before {
  background: url(../images/ikoner/ikon-twitter--hvid.svg) 18px 18px/contain;
}
footer a.youtube::before {
  background: url(../images/ikoner/ikon-youtube--hvid.svg) 18px 18px/contain;
}
footer a,
footer a:hover,
footer a:focus {
  color: white;
}
footer a:focus {
  box-shadow: 0 0 0 0.2rem #72728a;
  background-color: #72728a;
  outline: none;
}
footer address {
  margin-bottom: 0;
}
footer .footer-links {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0.5rem 0;
}
footer .footer-info {
  color: #89899d;
  border-top: 1px solid #434363;
  padding: 1.5rem 1rem 1.5rem;
}

.bg-graa {
  background-color: #e5e5e5;
}

.text-graa {
  color: #e5e5e5;
}

.bg-sand {
  background-color: #d1c5c3;
}

.text-sand {
  color: #d1c5c3;
}

.bg-skatdk {
  background-color: #2e99d9;
}

.text-skatdk {
  color: #2e99d9;
}

.bg-ufst {
  background-color: #1c69b9;
}

.text-ufst {
  color: #1c69b9;
}

.bg-adst {
  background-color: #aac800;
}

.text-adst {
  color: #aac800;
}

.bg-motorst {
  background-color: #008139;
}

.text-motorst {
  color: #008139;
}

.bg-toldst {
  background-color: #ffbb16;
}

.text-toldst {
  color: #ffbb16;
}

.bg-vurdst {
  background-color: #ff620c;
}

.text-vurdst {
  color: #ff620c;
}

.bg-gaeldst {
  background-color: #df0515;
}

.text-gaeldst {
  color: #df0515;
}

.bg-sktst {
  background-color: #aecbda;
}

.text-sktst {
  color: #aecbda;
}

.bg-skatdk.bg-hover, .bg-skatdk.bg-focus {
  transition: background-color 0.3s;
}
.bg-skatdk.bg-hover:hover, .bg-skatdk.bg-focus:focus {
  background-color: #77a5d5;
}

ul.link-list {
  padding-left: 0;
  padding-bottom: 1rem;
  margin: 0;
  list-style-type: none;
}
ul.link-list li {
  padding: 0.5em 0;
}
ul.link-list li.active {
  position: relative;
  font-weight: bold;
}
ul.link-list li.active::before {
  content: "";
  position: absolute;
  width: 0.2rem;
  height: 0.7em;
  margin-right: 0;
  display: inline-block;
  background-color: #14143c;
  top: 0.9rem;
  left: -1.5rem;
}
ul.link-list li a {
  text-decoration: none;
}
ul.link-list li a:hover {
  text-decoration: underline;
}

.navbar-content {
  position: absolute;
  right: 30px;
}

.navbar.navbar-dark a,
.navbar.navbar-dark a:not([href]) {
  color: white;
  display: inline-block;
  text-decoration: none;
  padding: auto;
  transition: background-color ease-in-out 0.25s;
}
.navbar.navbar-dark a.disabled,
.navbar.navbar-dark a:not([href]).disabled {
  color: #72728a;
  cursor: not-allowed;
}
.navbar.navbar-dark a:hover,
.navbar.navbar-dark a:not([href]):hover {
  text-decoration: none;
}
.navbar.navbar-dark a:hover.disabled,
.navbar.navbar-dark a:not([href]):hover.disabled {
  background-color: #14143c;
}

.navbar-toggler {
  color: white;
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-toggler-icon {
  height: 0.8em;
}

header .navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
  margin-right: 1rem;
  margin-left: 1rem;
  text-decoration: none;
}

@media (max-width: 767.98px) {
  header .nav-item .nav-link header .nav-item .nav-link.disabled,
header .nav-item .nav-link:hover {
    display: block;
    border-right: none;
  }
}
.wizard-trin {
  padding-left: 0;
  margin: 1rem 0;
  display: flex;
  position: relative;
  justify-content: center;
  counter-reset: navbar;
  list-style: none;
}
.wizard-trin li {
  position: relative;
  height: 5rem;
  counter-increment: navbar;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 0 1 195px;
  min-width: 0;
}
.wizard-trin li::after {
  content: "\e97d";
  font-family: "dap-ikon-font";
  font-size: 0.6rem;
  padding: 13px;
  border: solid 1px black;
  border-radius: 50%;
  background-color: white;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  position: absolute;
  left: calc(50% - 20px);
  bottom: 0;
}
.wizard-trin li span {
  white-space: nowrap;
  overflow: visible;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 12px;
}
.wizard-trin li .line {
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 20px;
  left: 50%;
  border-top: solid 1px black;
}
.wizard-trin li.active {
  font-weight: bold;
}
.wizard-trin li.active::after {
  border-width: 2px;
}
.wizard-trin li.active::after, .wizard-trin li.active ~ li::after {
  content: counter(navbar);
  font-size: 1rem;
  font-family: "Academy Sans", Arial, sans-serif;
  background-image: none;
}
.wizard-trin li.active .line, .wizard-trin li.active ~ li .line {
  border-top-style: dotted;
}
.wizard-trin li.active ~ li {
  color: #72728a;
}
.wizard-trin li.active ~ li::after {
  border-color: #72728a;
}
.wizard-trin li.active::after {
  font-weight: bold;
}
.wizard-trin li:first-child .line::before, .wizard-trin li:last-child .line::after {
  border-top-style: none;
}
.wizard-trin li:last-child .line {
  display: none;
}
.wizard-trin--flag li:last-child::after {
  content: "\e97e" !important;
  font-family: "dap-ikon-font" !important;
}

.navbar-wizard {
  padding-left: 0;
  counter-reset: wizard;
  margin-bottom: 2rem;
  display: inline-flex;
}
.navbar-wizard li {
  display: inline-flex;
  align-items: stretch;
  position: relative;
  color: #14143c;
  counter-increment: wizard;
}
.navbar-wizard li span {
  display: flex;
  align-items: center;
  border-top: solid 1px #14143c;
  border-bottom: solid 1px #14143c;
  padding-left: 20px;
  padding-right: 10px;
  margin-left: -10px;
  white-space: nowrap;
}
.navbar-wizard li:before, .navbar-wizard li.active:after, .navbar-wizard li:last-child:after {
  content: "";
  display: inline-block;
  width: 10px;
  height: 32px;
  background-image: url("data:image/svg+xml,%3csvg width='10' height='32' xmlns='http://www.w3.org/2000/svg'%3e%3cg%3e%3cpath stroke='%2314143c' d='m0,0l9.5,16l-9.5,16' fill='none'/%3e%3c/g%3e%3c/svg%3e");
  background-repeat: no-repeat;
}
.navbar-wizard li.active span, .navbar-wizard li:last-child span {
  margin-right: -10px;
}
.navbar-wizard li.active:after, .navbar-wizard li:last-child:after {
  transform: translateX(9px);
}
.navbar-wizard li.active span {
  margin-left: -1px;
  padding-left: 10px;
  color: white;
  background-color: #14143c;
}
.navbar-wizard li.active + li {
  padding-left: 10px;
}
.navbar-wizard li.active + li:before {
  content: none;
}
.navbar-wizard li.active:before {
  background-color: white;
  background-image: url("data:image/svg+xml,%3csvg width='10' height='32' xmlns='http://www.w3.org/2000/svg'%3e%3cg%3e%3cpath d='m0,0l10,16l-10,16l10,0l0,-32l-10,0z' fill-opacity='null' stroke-opacity='null' stroke='%2314143c' stroke-width='0' fill='%2314143c' id='svg_2'/%3e%3c/g%3e%3c/svg%3e");
}
.navbar-wizard li.active:after {
  background-image: url("data:image/svg+xml,%3csvg width='10' height='32' xmlns='http://www.w3.org/2000/svg'%3e%3cg%3e%3cpath fill='%2314143c' stroke='%2314143c' d='m0,1l9,16l-9,16l0,-32l0,0z' id='svg_1'/%3e%3c/g%3e%3c/svg%3e");
}
.navbar-wizard--hide-text li span {
  width: 42px;
  overflow: hidden;
  white-space: nowrap;
}
.navbar-wizard--hide-text li span:before {
  content: counter(wizard);
  margin-right: 20px;
}
.navbar-wizard--hide-text li.active span {
  width: 32px;
  padding-left: 10px;
}
@media (max-width: 767.98px) {
  .navbar-wizard li span {
    width: 42px;
    overflow: hidden;
    white-space: nowrap;
  }
  .navbar-wizard li span:before {
    content: counter(wizard);
    margin-right: 20px;
  }
  .navbar-wizard li.active span {
    width: 32px;
    padding-left: 10px;
  }
}

.nav-item {
  position: relative;
}
.nav-item .nav-link {
  color: white;
  transition: background-color 0.3s;
}
.nav-item .nav-link.active {
  font-weight: 600;
  padding-bottom: 8px;
  border-bottom: 2px #fff solid;
}
.nav-item .nav-link:hover {
  cursor: pointer;
  color: currentColor;
  box-shadow: 0 0 0 0 #a1a1b1;
  outline: none;
  text-decoration: none;
  text-decoration: none;
  border-bottom: 2px #fff solid;
}
.nav-item .nav-link:focus:not(.active) {
  color: white;
  border-color: #72728a;
}

.feedback {
  background-color: #e8e2e1;
  padding: 1rem;
}
.feedback__header {
  margin-bottom: 1rem;
}
.feedback button.btn {
  background-color: transparent;
  border: solid 1px gray;
  width: 10rem;
  transition: background-color ease-in-out 0.25s;
}
.feedback button.btn:hover,
.feedback button.btn .active {
  background-color: #14143c;
  color: white;
}

.modal-header {
  border-bottom: none;
}
.modal-header .close {
  width: 2.5rem;
  height: 2.5rem;
  background: #14143c url(../images/ikoner/ikon-luk--hvid.svg) center center no-repeat;
  background-size: 1.6rem 1.6rem;
  opacity: 1;
}
.modal-title {
  margin: 0;
}
.modal-footer {
  justify-content: space-between;
  flex-direction: row-reverse;
}


.modal-backdrop {
  z-index: 4;
}

button.close:focus {
  box-shadow: 0 0 0 0.3rem #72728a;
  outline: solid 1px white;
}

.block-table:not(.block-table--plain) tbody > tr:nth-child(odd) {
  background-color: #e3dbda;
}
.block-table.block-table--plain tbody > tr {
  border-top: solid 1px #e3dbda;
}
.block-table.block-table--plain tbody > tr:last-child {
  border-bottom: solid 1px #e3dbda;
}

@media (max-width: 768px) {
  .block-table tbody td {
    display: block;
    border: none !important;
  }
  .block-table tbody td:before {
    display: block;
    font-weight: bold;
  }
  .block-table thead tr {
    display: none;
  }
  .block-table.block-table--2-col tbody td {
    text-align: right;
  }
  .block-table.block-table--2-col tbody td:before {
    float: left;
    margin-right: 5px;
  }
}
.collapser {
  margin: 1.25rem 0;
}
.collapser__header {
  width: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  line-height: 1.5em;
  color: #14143c;
  font-weight: 600;
  text-align: left;
  background: transparent;
  border: none;
}
.collapser__header.h4 {
  line-height: 1.5em;
  margin-top: 1rem;
}
h3 > .collapser__header {
  line-height: 1.5em;
}
h2 > .collapser__header {
  line-height: 1.5em;
}
.collapser__header:focus {
  outline: 0;
  box-shadow: 0 0 0 0.3rem #72728a;
}
.collapser__header > img {
  margin-right: 1rem;
}
.collapser__header[aria-expanded=true] {
  border-bottom: 1px solid transparent;
}
.collapser__header[aria-expanded=true]::before {
  transform: translate(-25%, -25%) rotate(225deg);
}
.collapser__header[aria-expanded=false], .collapser__header:hover:not(:focus) {
  border-bottom: 1px solid #14143c;
}
.collapser__header::before {
  content: "";
  display: inline-block;
  flex-shrink: 0;
  width: 8px;
  height: 8px;
  margin-right: 1rem;
  margin-left: 1px;
  border-style: solid;
  border-width: 0 2px 2px 0;
  transform-origin: 75% 75%;
  transition: transform ease-in-out 0.3s;
  transform: translate(-25%, -25%) rotate(45deg);
}
.collapser__body {
  padding: 0.5rem 0 1.2rem 0;
  border-bottom: solid 1px #14143c;
}
.collapser__body > :first-child {
  margin-top: 0;
}

dl.inverse dt {
  font-weight: normal;
}
dl.inverse dd {
  font-weight: bold;
}
dl.dl-right dt {
  text-align: right;
}

.row-result {
  border-bottom: double #000;
  font-weight: 300;
}
.row-result--large {
  font-size: 3em;
}

.ikon.ikon--large {
  font-size: 1.5rem;
}

.rounded-icon,
.ikon-rundt {
  color: white;
  border-radius: 50%;
  width: 1.7rem;
  height: 1.7rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #14143c;
  text-decoration: none;
  border: none;
}
.rounded-icon.ikon-rundt--inverted, .rounded-icon.rounded-icon--inverted, .alert-primary .rounded-icon, .alert-danger .rounded-icon,
.ikon-rundt.ikon-rundt--inverted,
.ikon-rundt.rounded-icon--inverted,
.alert-primary .ikon-rundt,
.alert-danger .ikon-rundt {
  color: #14143c;
  background-color: white;
}
.rounded-icon.ikon-rundt--inverted:focus, .rounded-icon.rounded-icon--inverted:focus, .alert-primary .rounded-icon:focus, .alert-danger .rounded-icon:focus,
.ikon-rundt.ikon-rundt--inverted:focus,
.ikon-rundt.rounded-icon--inverted:focus,
.alert-primary .ikon-rundt:focus,
.alert-danger .ikon-rundt:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25);
}
.rounded-icon:hover,
.ikon-rundt:hover {
  color: white;
  background-color: #72728a;
  text-decoration: none;
}
.rounded-icon:focus,
.ikon-rundt:focus {
  background-color: #72728a;
  outline: 0;
  box-shadow: 0 0 0 2px white, 0 0 0 6px #72728a;
}
.rounded-icon--inverted,
.ikon-rundt--inverted {
  background-color: white;
  color: #14143c;
}
.rounded-icon--inverted:hover,
.ikon-rundt--inverted:hover {
  background-color: #d0d0d8;
  color: #14143c;
}
.rounded-icon--inverted:focus,
.ikon-rundt--inverted:focus {
  box-shadow: 0 0 0 0.2rem #949494;
}

.upload-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.upload-file:focus + label {
  box-shadow: 0 0 0 0.2rem #72728a;
}
.upload-file:focus + label .rounded-icon,
.upload-file:focus + label .ikon-rundt {
  background-color: #72728a;
}
.upload-file + label {
  cursor: pointer;
  display: inline-block;
}
.upload-file + label:hover {
  text-decoration: underline;
}

.ikon-download:before {
  content: "\e96a";
  transform: rotate(0.5turn) translateY(-0.125rem);
}

.btn .ikon {
  display: flex;
  justify-content: center;
}

legend {
  font-size: 1rem;
}

.required label:after,
.required legend:after {
  color: #df0515;
  content: " *";
}
.required input[type=checkbox] + label:after,
.required input[type=radio] + label:after {
  content: "";
}

legend.required:after {
  color: #df0515;
  content: " *";
}

.form-control[readonly],
.form-control[readonly] + .input-group-append > .input-group-text {
  border-style: dotted;
}

.custom-control-label {
  cursor: pointer;
  color: #14143c;
}

.custom-control-input:disabled ~ .custom-control-label {
  cursor: not-allowed;
}

.custom-checkbox .custom-control-input--inverted:checked ~ .custom-control-label::before {
  background-color: white;
}
.custom-checkbox .custom-control-input--inverted:checked ~ .custom-control-label::after {
  background-image: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='#14143c' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"), "#", "%23");
}
.custom-checkbox .custom-control-input--inverted:indeterminate ~ .custom-control-label::before {
  border-color: white;
  background-color: white;
}
.custom-checkbox .custom-control-input--inverted:indeterminate ~ .custom-control-label::after {
  background-image: str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='#14143c' d='M0 2h4'/%3e%3c/svg%3e"), "#", "%23");
}
.custom-checkbox .custom-control-input--inverted ~ .custom-control-label::before {
  border-color: white;
}

.invalid-feedback {
  font-size: 100%;
}

[data-watch-changes] ~ .changed-feedback {
  display: none;
  width: 100%;
  color: #008139;
}
[data-watch-changes].value-changed ~ .changed-feedback {
  display: block;
}

.input-group-text.align-units {
  min-width: 5rem;
}

.input-group-prepend .btn {
  min-width: 3.125rem;
}

.input-group-sum {
  border-bottom: double 4px black;
}
.input-group-sum .form-control,
.input-group-sum .input-group-text {
  border-color: transparent;
}

.input-group-borderless .form-control,
.input-group-borderless .input-group-text {
  border-color: transparent;
}

input.no-arrows::-webkit-outer-spin-button,
input.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.no-arrows[type=number] {
  -moz-appearance: textfield;
}
input.no-arrows[type=number] .was-validated .form-control:valid:focus,
input.no-arrows[type=number] .form-control.is-valid:focus {
  box-shadow: 0 0 0 0.2rem #72728a;
}
input.no-arrows[type=number] .was-validated .custom-control-input:valid:focus ~ .custom-control-label::before,
input.no-arrows[type=number] .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 2px white, 0 0 0 6px #72728a;
}

.custom-control {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}

.custom-control-input:not(:disabled):hover ~ .custom-control-label::before {
  border-width: 3px;
}

.alert {
  max-width: 650px;
  margin: auto;
  border-width: 2px;
}
.alert > :last-child {
  margin-bottom: 0;
  border-width: 2px;
}
.alert a {
  color: inherit;
}
.alert-info {
  background-color: #c0e0f4;
  border-color: #14143c;
}
.alert-warning {
  background-color: #ffebb9;
}
.alert-danger {
  background-color: #f5b4b9;
}
.alert-success {
  background-color: #b2d9c4;
  border-color: #008139;
}
.alert__header {
  font-weight: bold;
  font-size: 1.5rem;
  margin-top: 0;
  margin-bottom: 1rem;
  color: #14143c;
}
.alert-warning p, .alert-success p, .alert-alert p, .alert-danger p {
  color: #14143c;
}
.alert--light {
  background-color: white;
  color: #14143c;
}
.alert--light p {
  color: #14143c;
}
.alert-icon > .alert__header {
  padding-left: 45px;
  position: relative;
  margin-top: 0;
}
.alert-icon > .alert__header::before {
  height: 32px;
  display: flex;
  position: absolute;
  left: 0;
  top: -2px;
}
.alert-success.alert-icon > .alert__header::before {
  content: url(../images/ikoner/ikon-ok--groen.svg);
}
.alert-info.alert-icon > .alert__header::before {
  content: url(../images/ikoner/ikon-info--blaa.svg);
}
.alert-warning.alert-icon > .alert__header::before {
  content: url(../images/ikoner/ikon-warning--gul.svg);
}
.alert-danger.alert-icon > .alert__header::before {
  content: url(../images/ikoner/ikon-afvist--roed.svg);
}

.ikon.ikon-groen,
.ikon.ikon-blaa,
.ikon.ikon-gul,
.ikon.ikon-roed {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
}

.ikon.ikon-groen {
  background-color: #008139;
  color: white;
}

.ikon.ikon-groen::after {
  content: "\e97d";
  position: absolute;
  top: 10px;
  left: 8px;
  font-size: 13px;
}

.ikon.ikon-blaa {
  background-color: #14143c;
  color: white;
}

.ikon.ikon-gul {
  background-color: #ffbb16;
  color: #14143c;
  outline: 1px solid #14143c;
}

.ikon.ikon-roed {
  background-color: #df0515;
  color: white;
}

.ikon.ikon-blaa::after,
.ikon.ikon-gul::after,
.ikon.ikon-roed::after {
  content: "!";
  position: absolute;
  top: 4px;
  left: 12px;
  font-family: "Academy Sans", "Academy Sans Office", sans-serif;
  font-size: 24px;
}

.ie-flex-overflow-fix {
  flex: 1;
}

.blue-square {
  position: relative;
}
.blue-square::before {
  content: "";
  display: block;
  width: 2.2rem;
  height: 0.6rem;
  background: #14143c;
  position: absolute;
  left: 24px;
  top: 0px;
}
.blue-square[class*=col] {
  padding-top: 16px;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #14143c;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #434363;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(20, 20, 60, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #14143c;
  border-color: #14143c;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 6px #72728a;
}

.table {
  height: 1px;
}
.table caption {
  caption-side: top;
  color: inherit;
  font-weight: bold;
  margin: 0;
}
.table caption.table-status {
  padding: 0;
  background-color: #14143c;
  color: white;
}
.table caption.table-status > div {
  display: flex;
  position: relative;
  padding: 1rem 1.5rem;
  align-items: center;
}
.table caption.table-status > div::after {
  content: "";
  border-top: 0.649519053rem solid;
  border-right: 0.375rem solid transparent;
  border-left: 0.375rem solid transparent;
  border-bottom: 0;
  position: absolute;
  bottom: -10px;
  left: 30px;
  border-top-color: #14143c;
}
.table caption.table-status > div :last-child {
  margin-left: auto;
}
.table tr.deleting td {
  opacity: 0.5;
}
.table thead tr:first-child th,
.table thead tr:first-child td {
  border-top: solid 2px #14143c;
}
.table thead tr:last-child th,
.table thead tr:last-child td {
  border-bottom: solid 2px #14143c;
}
.table thead th {
  vertical-align: top;
  font-weight: normal;
}
.table thead th.sortable {
  padding: 0;
}
.table thead th.sortable > .btn {
  padding: calc(1.5rem - 2px);
  padding-right: 36px;
  text-align: left;
  width: calc(100% - 4px);
  position: relative;
  margin: 0 2px;
}
.table thead th.sortable.sorted > .btn {
  font-weight: bold;
}
.table thead th.sortable.sorted > .btn::after {
  content: "";
  position: absolute;
  right: 1.5rem;
  top: calc(50% - 0.25rem);
  border-top: 0.433012702rem solid;
  border-right: 0.25rem solid transparent;
  border-left: 0.25rem solid transparent;
  border-bottom: 0;
}
.table thead th.sortable.sorted.ascending > .btn::after {
  transform: rotate(0.5turn);
}
.table th,
.table td {
  transition: opacity ease-in 0.5s;
}
.table th.checkbox-cell,
.table td.checkbox-cell {
  width: 24px;
}
.table th.checkbox-cell .custom-control,
.table td.checkbox-cell .custom-control {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 1.5rem;
}
.table th.checkbox-cell .custom-control-label::before,
.table td.checkbox-cell .custom-control-label::before {
  left: -1.5rem;
}
.table th.checkbox-cell .custom-control-label::after,
.table td.checkbox-cell .custom-control-label::after {
  left: -1.5rem;
}
.table th .dropdown.action-dropdown,
.table td .dropdown.action-dropdown {
  height: 1.5rem;
  min-width: 6rem;
}
.table th .dropdown.action-dropdown .dropdown-toggle,
.table td .dropdown.action-dropdown .dropdown-toggle {
  background-color: transparent;
  padding: 0rem;
  background-image: url(../images/actiondots.svg);
  background-repeat: no-repeat;
  background-position: 3px -1px;
  height: 1.5rem;
  min-width: 1rem;
}
.table th .dropdown.action-dropdown .dropdown-toggle:before, .table th .dropdown.action-dropdown .dropdown-toggle:after,
.table td .dropdown.action-dropdown .dropdown-toggle:before,
.table td .dropdown.action-dropdown .dropdown-toggle:after {
  border: none;
}
.table th .dropdown.action-dropdown .dropdown-toggle:focus,
.table td .dropdown.action-dropdown .dropdown-toggle:focus {
  box-shadow: 0 0 0 3px #72728a;
  outline: none;
}
.table th .dropdown.action-dropdown .dropdown-menu,
.table td .dropdown.action-dropdown .dropdown-menu {
  min-width: 7rem;
  white-space: nowrap;
  padding: 0;
  border: none;
  top: -4px;
  right: 1rem;
  left: auto;
  text-align: right;
  margin-right: 3px;
  height: 1.5rem;
}
.table th .dropdown.action-dropdown .action,
.table td .dropdown.action-dropdown .action {
  margin-right: 0.5rem;
}
.table tr.selected {
  background-color: #e5e5e5;
}
.table tr:hover td.actions-cell .action,
.table tr.selected td.actions-cell .action {
  opacity: 1;
}
.table tr:hover td .dropdown-menu,
.table tr.selected td .dropdown-menu {
  display: block;
  background-color: #e5e5e5;
}

.width-1em {
  width: 1em;
}

.width-2em {
  width: 2em;
}

.width-3em {
  width: 3em;
}

.width-4em {
  width: 4em;
}

.width-5em {
  width: 5em;
}

.btn-table-cell {
  width: 100%;
  height: 100%;
}
.btn-table-cell:hover, .btn-table-cell:focus {
  background-color: #72728a;
  color: white;
}
.btn-table-cell:focus {
  border: solid 1px white;
  box-shadow: 0 0 0 0.2rem #72728a;
}

.form-control-sm.paginator {
  width: 3em;
}

.custom-select.paginator {
  height: calc(1.5em + 0.5rem + 2px);
  padding-left: 0.25rem;
  padding-right: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.5rem center/8px 10px no-repeat;
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
  padding-right: 30px;
  margin-top: auto;
  margin-bottom: auto;
}

.dropdown.action-dropdown .dropdown-toggle {
  border: none;
}
.dropdown-toggle {
  background-color: #14143c;
  color: white;
  border: solid 1px #14143c;
  padding: 0.75rem 1rem;
  transition: background-color 0.3s;
}
.dropdown-toggle--light {
  background-color: white;
  color: #14143c;
}
.dropdown-toggle:focus {
  outline: none;
}
h1 .dropdown-toggle, h2 .dropdown-toggle {
  background: white;
  color: inherit;
  font-weight: inherit;
  border: solid 1px #14143c;
}
h1 .dropdown-toggle:focus, h2 .dropdown-toggle:focus {
  outline: none;
  box-shadow: 0 0 0 0.3rem #72728a;
}
h1 .dropdown-toggle:hover, h1 .dropdown-toggle:active, h2 .dropdown-toggle:hover, h2 .dropdown-toggle:active {
  background-color: #d0d0d8;
}
h1 .dropdown-toggle {
  padding: 1rem 1.5rem;
}
h2 .dropdown-toggle {
  padding: 0.8rem 1.1rem;
}
.dropdown-toggle::after {
  border-top-width: 0.3em;
  transition: transform ease-in-out 0.2s;
}
.dropdown-toggle[aria-expanded=true]::after {
  transform: rotate(0.5turn);
}
.dropdown-menu {
  margin: 0;
  padding-left: 1rem padding-right 0.8rem;
}
h1 .dropdown-menu, h2 .dropdown-menu {
  margin-top: -1px;
  border: none;
  padding: 0;
  min-width: auto;
}
.dropdown-item {
  cursor: pointer;
  transition: background-color 0.3s;
}
h1 .dropdown-item, h2 .dropdown-item {
  border-bottom: none;
}
h1 .dropdown-item:last-child, h2 .dropdown-item:last-child {
  border-bottom: 1px solid #14143c;
}
h1 .dropdown-item:active, h1 .dropdown-item.active, h2 .dropdown-item:active, h2 .dropdown-item.active {
  color: #14143c;
  background-color: #d0d0d8;
}
h1 .dropdown-item:hover, h1 .dropdown-item:focus, h2 .dropdown-item:hover, h2 .dropdown-item:focus {
  outline: none;
  background-color: #d0d0d8;
}
h1 .dropdown-item {
  padding: 1rem 1.5rem;
}
h2 .dropdown-item {
  padding: 0.8rem 1.1rem;
}

@media (max-width: 767.98px) {
  .dropdown {
    padding-right: 0;
    background-color: red;
    display: inline-block;
  }
}
.alert.filter {
  display: inline-flex;
  align-items: center;
  border: solid 1px #14143c;
  padding: 0.25rem 0.25rem 0.25rem 0.5rem;
  position: relative;
  margin-bottom: 1rem;
  margin-right: 1rem;
}
.alert.filter .close {
  height: auto;
  width: auto;
  position: relative;
  opacity: 1;
  font-size: 1.7rem;
  color: #14143c;
  padding: 0 0.25rem;
  margin-left: 0.25rem;
  display: flex;
  align-items: center;
}
.alert.filter .close:focus {
  outline: none;
}

@media (prefers-reduced-motion: reduce) {
  .spinner {
    animation: none !important;
  }
}
.has-spinner {
  transition: color 0.3s ease-in-out;
  position: relative;
}
.has-spinner .spinner {
  opacity: 0;
  position: absolute;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
}
[aria-busy=true] .has-spinner, .has-spinner[aria-busy=true] {
  color: transparent !important;
}
[aria-busy=true] .has-spinner .spinner, .has-spinner[aria-busy=true] .spinner {
  opacity: 1;
  display: block;
}

#ds-full-page-spinner {
  filter: none;
}

.spinner {
  height: 30px;
  width: 30px;
  animation-name: rotating;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  transition: opacity 0.3s ease-in-out;
  background-size: contain;
}
#ds-full-page-spinner .spinner {
  position: static;
}
.spinner.spinner--light {
  background-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0D%0A%3Csvg%20version%3D%221.1%22%20%0D%0A%09xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20%0D%0A%09xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20width%3D%2238px%22%20height%3D%2238px%22%20viewBox%3D%220%200%2038%2038%22%20xml%3Aspace%3D%22preserve%22%3E%0D%0A%09%3Cpath%20opacity%3D%220.5%22%20fill%3D%22%23FFFFFF%22%20d%3D%22M19%2C3.1c8.8%2C0%2C15.9%2C7.1%2C15.9%2C15.9S27.8%2C34.9%2C19%2C34.9c-4.3%2C0-8.3-1.7-11.1-4.5l-2.1%2C2.2%0D%0A%09C9.2%2C35.9%2C13.8%2C38%2C19%2C38c10.5%2C0%2C19-8.5%2C19-19S29.5%2C0%2C19%2C0l0%2C0C13.8%2C0%2C9.2%2C2.1%2C5.7%2C5.4l2.2%2C2.2C10.8%2C4.8%2C14.7%2C3.1%2C19%2C3.1%22%2F%3E%0D%0A%09%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M7.9%2C30.4c-3-2.9-4.8-6.9-4.8-11.4c0-4.5%2C1.8-8.5%2C4.8-11.4L5.7%2C5.4C2.2%2C8.9%2C0%2C13.7%2C0%2C19%0D%0A%09c0%2C5.3%2C2.2%2C10.2%2C5.7%2C13.6L7.9%2C30.4z%22%2F%3E%0D%0A%3C%2Fsvg%3E%0D%0A");
}
.spinner.spinner--dark {
  background-image: url("data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20%0D%0A%09xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20x%3D%220px%22%20y%3D%220px%22%20width%3D%2250.6px%22%20height%3D%2250.6px%22%20viewBox%3D%22-0.5%20-0.5%2050.6%2050.6%22%20enable-background%3D%22new%20-0.5%20-0.5%2050.6%2050.6%22%20xml%3Aspace%3D%22preserve%22%3E%0D%0A%09%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M24.8-0.5c-14%2C0-25.3%2C11.3-25.3%2C25.3s11.3%2C25.3%2C25.3%2C25.3s25.3-11.3%2C25.3-25.3S38.8-0.5%2C24.8-0.5z%0D%0A%09%20M45.1%2C24.8c0%2C11.2-9.1%2C20.3-20.3%2C20.3C13.6%2C45.1%2C4.5%2C36%2C4.5%2C24.8S13.6%2C4.5%2C24.8%2C4.5C36%2C4.5%2C45.1%2C13.6%2C45.1%2C24.8z%22%2F%3E%0D%0A%09%3Cg%3E%0D%0A%09%09%3Cpath%20opacity%3D%220.5%22%20fill%3D%22%2314143C%22%20enable-background%3D%22new%22%20d%3D%22M24.8%2C4c11.5%2C0%2C20.8%2C9.3%2C20.8%2C20.8s-9.3%2C20.8-20.8%2C20.8%0D%0A%09%09c-5.7%2C0-10.8-2.3-14.5-5.9l-2.8%2C2.9c4.5%2C4.4%2C10.6%2C7%2C17.3%2C7c13.7%2C0%2C24.8-11.1%2C24.8-24.8S38.5%2C0%2C24.8%2C0l0%2C0C18.1%2C0%2C12%2C2.7%2C7.5%2C7.1%0D%0A%09%09l2.8%2C2.9C14%2C6.3%2C19.2%2C4%2C24.8%2C4%22%2F%3E%0D%0A%09%09%3Cpath%20fill%3D%22%2314143C%22%20d%3D%22M10.3%2C39.7C6.4%2C35.9%2C4%2C30.6%2C4%2C24.8s2.4-11.1%2C6.3-14.9L7.5%2C7.1C2.9%2C11.6%2C0%2C17.9%2C0%2C24.8%0D%0A%09%09c0%2C7%2C2.9%2C13.3%2C7.5%2C17.8L10.3%2C39.7z%22%2F%3E%0D%0A%09%3C%2Fg%3E%0D%0A%3C%2Fsvg%3E%0D%0A");
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
body.modal-open[aria-busy=true] .site-wrapper > *, body.modal-open[aria-busy=true].site-wrapper > * {
  filter: blur(2px);
}
body.modal-open[aria-busy=true] .modal-backdrop {
  background-color: white;
  opacity: 0.8;
}
body.modal-open[aria-busy=true] .modal-content {
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

fieldset[disabled] {
  pointer-events: none;
}

a.related-link {
  position: relative;
  margin-bottom: 0.7rem;
  padding: 0 1rem 0 2rem;
  text-decoration: none;
  display: inline-block;
}
a.related-link:hover, a.related-link:focus {
  color: white;
  text-decoration: none;
  outline: none;
  background: #72728a;
}
a.related-link:hover::before, a.related-link:hover::before, a.related-link:focus::before, a.related-link:focus::before {
  left: 0.7rem;
}
a.related-link:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0.75rem;
  margin-top: -4px;
  width: 8px;
  height: 8px;
  border: solid currentColor;
  border-width: 2px 2px 0 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: all 0.3s;
}

.bg-graa {
  background-color: #e5e5e5;
}

.text-graa {
  color: #e5e5e5;
}

.bg-sand {
  background-color: #d1c5c3;
}

.text-sand {
  color: #d1c5c3;
}

.bg-skatdk {
  background-color: #2e99d9;
}

.text-skatdk {
  color: #2e99d9;
}

.bg-ufst {
  background-color: #1c69b9;
}

.text-ufst {
  color: #1c69b9;
}

.bg-adst {
  background-color: #aac800;
}

.text-adst {
  color: #aac800;
}

.bg-motorst {
  background-color: #008139;
}

.text-motorst {
  color: #008139;
}

.bg-toldst {
  background-color: #ffbb16;
}

.text-toldst {
  color: #ffbb16;
}

.bg-vurdst {
  background-color: #ff620c;
}

.text-vurdst {
  color: #ff620c;
}

.bg-gaeldst {
  background-color: #df0515;
}

.text-gaeldst {
  color: #df0515;
}

.bg-sktst {
  background-color: #aecbda;
}

.text-sktst {
  color: #aecbda;
}

.bg-skatdk.bg-hover, .bg-skatdk.bg-focus {
  transition: background-color 0.3s;
}
.bg-skatdk.bg-hover:hover, .bg-skatdk.bg-focus:focus {
  background-color: #77a5d5;
}

.language-chooser {
  border: none;
  color: white;
  transition: background-color 0.3s;
  text-align: left;
  display: flex;
  align-items: center;
  border-bottom: var(--border--sm) solid transparent;
  cursor: pointer;
}

@media (max-width: 767.98px) {
  .language-chooser {
    position: relative;
    top: 0;
    display: inline-block;
  }
}
.language-chooser:focus,
.language-chooser:hover {
  outline: none;
  color: #14143c;
  box-shadow: 0 0 0 var(--border--md) var(--color-primary--60);
  box-shadow: 0 0 0 0.3rem #72728a inset;
}

@media (max-width: 768px) {
  .language-chooser::before {
    content: url(../images/ikoner/ikon-sprogvaelger--hvid.svg);
    position: relative;
    top: 15%;
    margin: auto;
    width: 14px;
    padding-top: 0;
    padding-bottom: 0;
    height: auto;
    align-items: center;
    display: flex;
  }
}
@media (min-width: 768px) {
  .language-chooser::after {
    content: url(../images/ikoner/ikon-sprogvaelger--hvid.svg);
    position: relative;
    top: 3px;
    width: 14px;
    height: 100%;
    align-items: center;
    display: flex;
    font-size: x-small;
  }
}
.language-chooser2 {
  display: flex;
  list-style-type: none;
}

.language-chooser2 li:not(:last-child)::after {
  content: "";
  background-color: #d0d0d8;
  width: 1px;
  height: 16px;
  position: absolute;
  right: 0.5rem;
  top: 6px;
}

.language-chooser2 li {
  padding-right: 1rem;
  position: relative;
}

.language-chooser2 li a[aria-current]:not([aria-current=false]) {
  text-decoration: none;
  cursor: text;
  font-weight: bold;
}

.language-chooser2 li a[aria-current]:not([aria-current=false])::before {
  content: url(../images/ikoner/ikon-ok.svg);
  position: absolute;
  width: 14px;
  left: -19px;
}

ul.language-chooser2::before {
  content: url(../images/ikoner/ikon-sprogvaelger.svg);
  position: absolute;
  left: 5px;
  width: 14px;
  top: 10px;
}

@media (min-width: 768px) {
  .language-chooser::before {
    margin-right: 1rem;
  }
}
.card {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: 0 0 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 0 0;
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 0;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card-deck .card {
  margin-bottom: 24px;
}

@media (min-width: 576px) {
  .card-deck {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
    margin-right: -24px;
    margin-left: -24px;
  }

  .card-deck .card {
    -webkit-box-flex: 1;
    flex: 1 0 0%;
    margin-right: 24px;
    margin-bottom: 0;
    margin-left: 24px;
  }
}
.card-group > .card {
  margin-bottom: 24px;
}

@media (min-width: 576px) {
  .card-group {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
  }

  .card-group > .card {
    -webkit-box-flex: 1;
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}
.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }

  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}
.list-group {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #14143c;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #14143c;
  border-color: #14143c;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #0a0a1f;
  background-color: #bdbdc8;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #0a0a1f;
  background-color: #afafbc;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #0a0a1f;
  border-color: #0a0a1f;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #85610b;
  background-color: #ffecbe;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #85610b;
  background-color: #ffe5a5;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #85610b;
  border-color: #85610b;
}

.list-group-item-danger {
  color: #74030b;
  background-color: #f6b9bd;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #74030b;
  background-color: #f3a2a8;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #74030b;
  border-color: #74030b;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.button-unstyled, .skat-date-picker__calendar__year, .skat-date-picker__calendar__previous-year-chunk,
.skat-date-picker__calendar__next-year-chunk, .skat-date-picker__calendar__month, .skat-date-picker__calendar__year-selection,
.skat-date-picker__calendar__month-selection, .skat-date-picker__calendar__date, .skat-date-picker__calendar__previous-year,
.skat-date-picker__calendar__previous-month,
.skat-date-picker__calendar__next-year,
.skat-date-picker__calendar__next-month, .skat-date-picker__button {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  font-weight: 400;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: auto;
  color: inherit;
  min-height: auto;
}

.button-unstyled:hover, .skat-date-picker__calendar__year:hover, .skat-date-picker__calendar__previous-year-chunk:hover,
.skat-date-picker__calendar__next-year-chunk:hover, .skat-date-picker__calendar__month:hover, .skat-date-picker__calendar__year-selection:hover,
.skat-date-picker__calendar__month-selection:hover, .skat-date-picker__calendar__date:hover, .skat-date-picker__calendar__previous-year:hover,
.skat-date-picker__calendar__previous-month:hover,
.skat-date-picker__calendar__next-year:hover,
.skat-date-picker__calendar__next-month:hover, .skat-date-picker__button:hover, .button-unstyled:focus, .skat-date-picker__calendar__year:focus, .skat-date-picker__calendar__previous-year-chunk:focus,
.skat-date-picker__calendar__next-year-chunk:focus, .skat-date-picker__calendar__month:focus, .skat-date-picker__calendar__year-selection:focus,
.skat-date-picker__calendar__month-selection:focus, .skat-date-picker__calendar__date:focus, .skat-date-picker__calendar__previous-year:focus,
.skat-date-picker__calendar__previous-month:focus,
.skat-date-picker__calendar__next-year:focus,
.skat-date-picker__calendar__next-month:focus, .skat-date-picker__button:focus {
  outline: 3px solid #72728a;
  position: relative;
  z-index: 100;
}

.skat-date-picker__wrapper {
  display: block;
  position: relative;
}
.skat-date-picker__wrapper:focus {
  outline: 0;
}

.skat-date-picker--active .date-picker__calendar, .date-picker--initialized .date-picker__calendar {
  z-index: 400;
}

.skat-date-picker__external-input {
  max-width: none;
  width: calc(100% - 16px - 16px);
}

.skat-date-picker__button {
  align-self: stretch;
  background-color: #14143c;
  width: 3.125rem;
  height: 3.125rem;
  align-items: center;
  justify-content: center;
  display: block;
  border-width: 1px;
  border-style: solid;
  border-color: #14143c;
}

.skat-date-picker__button:before {
  color: white;
  font-family: dap-ikon-font;
  font-size: 1.5rem;
  content: "\e949";
}

.skat-date-picker--initialized .skat-date-picker__wrapper {
  display: flex;
}

.skat-date-picker__calendar {
  border: 1px solid gray;
  background-color: white;
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.5);
  left: 0;
  position: absolute;
  width: 100%;
  min-width: 232px;
  max-width: 282px;
  z-index: 100;
  margin-top: 4px;
  font-size: 0.8rem;
}

.skat-date-picker__calendar__table {
  border-spacing: 0;
  border-collapse: collapse;
  table-layout: fixed;
  text-align: center;
  width: 100%;
}
.skat-date-picker__calendar__table thead {
  border-bottom: 1px solid lightgray;
}
.skat-date-picker__calendar__table th {
  font-weight: normal;
}
.skat-date-picker__calendar__table td {
  padding: 0;
}

.skat-date-picker__calendar__month-picker .skat-date-picker__calendar__table thead, .skat-date-picker__calendar__month-picker .skat-date-picker__calendar__table tr, .skat-date-picker__calendar__year-picker .skat-date-picker__calendar__table thead, .skat-date-picker__calendar__year-picker .skat-date-picker__calendar__table tr {
  border: 0;
}

.skat-date-picker__calendar__row {
  display: flex;
  text-align: center;
  width: 100%;
  background-color: whitesmoke;
  border-bottom: 1px solid lightgray;
}

.skat-date-picker__calendar__cell {
  background-color: whitesmoke;
  color: black;
  flex: 1;
}

.skat-date-picker__calendar__cell--center-items {
  display: flex;
  justify-content: center;
  align-items: center;
}

.skat-date-picker__calendar__previous-year,
.skat-date-picker__calendar__previous-month,
.skat-date-picker__calendar__next-year,
.skat-date-picker__calendar__next-month {
  background-position: center;
  height: 30px;
}

.skat-date-picker__calendar__previous-year:not([disabled]):before {
  color: #14143c;
  font-family: dap-ikon-font;
  font-size: 1rem;
  content: "\e97b";
}

.skat-date-picker__calendar__previous-month:not([disabled]):before {
  color: #14143c;
  font-family: dap-ikon-font;
  font-size: 1rem;
  content: "\e97b";
}

.skat-date-picker__calendar__next-year:not([disabled]):before {
  color: #14143c;
  font-family: dap-ikon-font;
  font-size: 1rem;
  content: "\e903";
}

.skat-date-picker__calendar__next-month:not([disabled]):before {
  color: #14143c;
  font-family: dap-ikon-font;
  font-size: 1rem;
  content: "\e903";
}

.skat-date-picker__calendar__day-of-week {
  padding: 6px 0px;
}

.skat-date-picker__calendar__date {
  text-align: right;
  width: 100%;
  padding: 10px 12px 10px 0px;
  position: relative;
}
.skat-date-picker__calendar__date:after {
  position: absolute;
  top: -1px;
  bottom: 0px;
  left: -1px;
  right: 0px;
  border: 1px solid lightgray;
  content: "";
  display: block;
  pointer-events: none;
}
.skat-date-picker__calendar__date[disabled] {
  opacity: 0.3;
  cursor: not-allowed;
}
.skat-date-picker__calendar__date:focus {
  outline: none;
}
.skat-date-picker__calendar__date:focus:before {
  outline: 3px solid #72728a;
  position: absolute;
  top: -1px;
  bottom: 0px;
  left: -1px;
  right: 0px;
  content: "";
  display: block;
  pointer-events: none;
  box-shadow: black;
}
.skat-date-picker__calendar__date--next-month:not([disabled]), .skat-date-picker__calendar__date--previous-month:not([disabled]) {
  color: gray;
  text-indent: -99999px;
  background-color: whitesmoke;
}
.skat-date-picker__calendar__date--selected, .skat-date-picker__calendar__date--range-date {
  background-color: #14143c;
  color: white;
  font-weight: 600;
}
.skat-date-picker__calendar__date--selected:not([disabled]), .skat-date-picker__calendar__date--range-date:not([disabled]) {
  background-color: #14143c;
  color: white;
}
.skat-date-picker__calendar__date--within-range {
  background-color: #72728a;
  color: white;
}
.skat-date-picker__calendar__date--within-range:not([disabled]) {
  background-color: #72728a;
}
.skat-date-picker__calendar__date--within-range:not([disabled]):hover {
  background-color: #72728a;
}
.skat-date-picker__calendar__date--within-range:not([disabled]):focus {
  background-color: #72728a;
}
.skat-date-picker__calendar__date--within-range:not([disabled]):active {
  background-color: #72728a;
}

.skat-date-picker__calendar__month-label {
  flex: 4;
  text-align: center;
}

.skat-date-picker__calendar__year-selection,
.skat-date-picker__calendar__month-selection {
  display: inline-block;
  height: 100%;
  padding: 8px 3px;
  width: auto;
  font-weight: 600;
}

.skat-date-picker__calendar__month-picker {
  padding: 20px 5px;
}

.skat-date-picker__calendar__month {
  padding: 10px 0;
  width: 100%;
  display: block;
  text-align: center;
}
.skat-date-picker__calendar__month[disabled] {
  opacity: 0.3;
  cursor: not-allowed;
}
.skat-date-picker__calendar__month--selected {
  color: black;
  font-weight: 600;
}
.skat-date-picker__calendar__month--selected:not([disabled]):hover {
  color: black;
}
.skat-date-picker__calendar__month--selected:not([disabled]):focus {
  color: black;
}

.skat-date-picker__calendar__year-picker {
  padding: 20px 5px;
}

.skat-date-picker__calendar__previous-year-chunk,
.skat-date-picker__calendar__next-year-chunk {
  background-position: center;
  margin: auto;
  padding: 0px 8px;
}

.skat-date-picker__calendar__previous-year-chunk:not([disabled]):before {
  color: #14143c;
  font-family: dap-ikon-font;
  font-size: 1rem;
  content: "\e97b";
}

.skat-date-picker__calendar__next-year-chunk:not([disabled]):before {
  color: #14143c;
  font-family: dap-ikon-font;
  font-size: 1rem;
  content: "\e903";
}

.skat-date-picker__calendar__year {
  padding: 10px 0;
  width: 100%;
  display: block;
  text-align: center;
}
.skat-date-picker__calendar__year[disabled] {
  opacity: 0.3;
  cursor: not-allowed;
}
.skat-date-picker__calendar__year--selected {
  color: black;
  font-weight: 600;
}
.skat-date-picker__calendar__year--selected:not([disabled]):hover {
  color: black;
}
.skat-date-picker__calendar__year--selected:not([disabled]):focus {
  color: black;
}

.topmenu__link {
  color: white;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 1rem 0.5rem 2.5rem;
  transition: all 0.3s;
  background: #14143c;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.topmenu__link:not(.active)::before {
  content: "";
  display: block;
  position: absolute;
  left: 16px;
  top: calc(50% - 4px);
  width: 8px;
  height: 8px;
  border: solid currentColor;
  border-width: 2px 2px 0 0;
  transform: rotate(45deg);
  transition: all 0.3s;
}
.topmenu__link.active {
  font-weight: bold;
}
.topmenu__link.active::before {
  font-family: "dap-ikon-font";
  font-weight: normal;
  display: inline-block;
  content: "\e97d";
  margin-right: 1rem;
  font-size: 11px;
  position: absolute;
  left: 16px;
}
.topmenu__link:hover, .topmenu__link:focus {
  background: #72728a;
  text-decoration: none;
  color: #fff;
}
.topmenu__link:hover:not(.active)::before, .topmenu__link:focus:not(.active)::before {
  left: 0.7rem;
}

.form-control-sm.paginator {
  width: 3em;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.custom-select.paginator {
  height: calc(1.5em + 0.5rem + 2px);
  padding-left: 0.25rem;
  padding-right: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.5rem center/8px 10px no-repeat;
}

.pagination-container {
  display: flex;
}

.cell-one {
  border-right: 1px solid #e5e5e5;
  padding-right: 1rem;
}

.cell-two {
  margin-left: 1rem;
}

.cell-three {
  margin-left: auto;
}

@media screen and (max-width: 728px) {
  .pagination-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .cell-one {
    grid-column: span 1;
    margin-left: auto;
  }

  .cell-two {
    grid-column: span 1;
  }

  .cell-three {
    grid-column: span 2;
    margin-top: 1rem;
    margin-right: auto;
  }
}
@media screen and (max-width: 480px) {
  .pagination-container {
    grid-template-columns: 1fr;
  }

  .cell-one {
    margin-right: auto;
    border-right: none;
    padding-right: 0;
  }

  .cell-two {
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
  }

  .cell-three {
    grid-column: span 1;
  }
}
@media print {
  header .bg-primary {
    background-color: transparent !important;
  }
  header .header-element {
    color: transparent;
    background-color: transparent !important;
  }
  header .header-element.bg-skatdk {
    color: transparent;
  }
  header .header-element.skatdklogo {
    background-image: url(../images/skatdk-logo-print.svg);
  }

  .with-caret::after {
    content: none;
  }

  .infocontent {
    display: inline-block !important;
    position: static;
    background-color: transparent !important;
    padding-top: 0 !important;
    text-align: right;
  }
  .infocontent p {
    color: black;
  }
  .infocontent button {
    display: none !important;
  }
}