@font-face {
  font-family: 'Academy Sans';
  src: url('../fonts/AcademySans/AcademySans-Regular.eot');
  src: url('../fonts/AcademySans/AcademySans-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/AcademySans/AcademySans-Regular.woff2') format('woff2'),
    url('../fonts/AcademySans/AcademySans-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Academy Sans';
  src: url('../fonts/AcademySans/AcademySans-Italic.eot');
  src: url('../fonts/AcademySans/AcademySans-Italic.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/AcademySans/AcademySans-Italic.woff2') format('woff2'),
    url('../fonts/AcademySans/AcademySans-Italic.woff') format('woff');
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Academy Sans';
  src: url('../fonts/AcademySans/AcademySans-Medium.eot');
  src: url('../fonts/AcademySans/AcademySans-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/AcademySans/AcademySans-Medium.woff2') format('woff2'),
    url('../fonts/AcademySans/AcademySans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Academy Sans';
  src: url('../fonts/AcademySans/AcademySans-Demibold.eot');
  src: url('../fonts/AcademySans/AcademySans-Demibold.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/AcademySans/AcademySans-Demibold.woff2') format('woff2'),
    url('../fonts/AcademySans/AcademySans-Demibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Academy Sans';
  src: url('../fonts/AcademySans/AcademySans-Bold.eot');
  src: url('../fonts/AcademySans/AcademySans-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/AcademySans/AcademySans-Bold.woff2') format('woff2'),
    url('../fonts/AcademySans/AcademySans-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Academy Sans';
  src: url('../fonts/AcademySans/AcademySans-Black.eot');
  src: url('../fonts/AcademySans/AcademySans-Black.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/AcademySans/AcademySans-Black.woff2') format('woff2'),
    url('../fonts/AcademySans/AcademySans-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: fallback;
}

h1,
.h1 {
  margin: 1rem 0 1rem;
  font-weight: 900;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

  @media screen and (max-width: 576px) {
    font-size: 9vw;
  }

  &.small {
    font-size: 28px;
    font-weight: 900;
  }
}

h2,
.h2 {
  margin: 2rem 0 0.6rem;
}

h3,
.h3 {
  margin: 2rem 0 0.4rem;
}

h4,
.h4 {
  margin: 2rem 0 0.4rem;
}

h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: 600;
}

h1,
.h1 {
  + h2,
  + .h2,
  + .table caption,
  + h3,
  + .h3,
  + h4,
  + .h4 {
    margin-top: 0;
  }
}

h2,
.h2 {
  + h3,
  + .h3,
  + h4,
  + .h4 {
    margin-top: 0;
  }
}

h3,
.h3 {
  + h4,
  + .h4 {
    margin-top: 0;
  }
}

p,
ol,
ul {
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  max-width: 47rem;
}

a {
  &:focus {
    color: $blaa-60;
    box-shadow: 0;
  }
}

.hyphens {
  -ms-hyphens: auto !important;
  -webkit-hyphens: auto !important;
  hyphens: auto !important;
}

code {
  hyphens: none;
}

p,
li,
table {
  color: $blaa-80;
}
