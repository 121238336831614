@use 'sass:math';

header {
  margin-bottom: 1.5rem;
}
.btn-other {
  height: 42px;
  margin: auto 1rem;
  padding: 0;
  display: flex;
  align-items: center;
  text-decoration: none !important;
  &.inactive {
    border-bottom: 2px transparent solid;
  }

  &.active,
  &:hover {
    border-bottom: 2px white solid;
  }
  &:focus {
    outline: none;
    box-shadow: $btn-focus-box-shadow inset;
  }
}

.btn-logon {
  max-width: 15.3rem;
  background: $blaa;
  display: inline-block;
  color: $blaa !important;
  text-decoration: none;
  border-top: 1px solid $blaa;
  border-left: 1px solid $blaa;
  border-right: 1px solid $blaa;
  border-bottom: 1px solid $blaa;
  position: relative;
  &.active {
    border-bottom: none;
  }
  &:hover {
    color: $blaa;
  }
  &:focus {
    outline: solid 1px white;
    box-shadow: $btn-focus-box-shadow inset;
    color: $blaa;
  }
}

@include media-breakpoint-down(sm) {
  .btn-logon {
    height: 44px;
    border: 0 solid transparent;
    margin-right: 0;
  }
}

.header-element {
  display: flex;
  @include media-breakpoint-down(md) {
    height: 44px;
  }
  @include media-breakpoint-up(md) {
    height: 72px;
  }
  &.active {
    border-bottom: 2px white solid;
  }
  color: white;
  &:hover {
    color: white;
  }
  &:focus {
    outline: solid 1px white;
    box-shadow: $btn-focus-box-shadow inset;
    background-color: $blaa;
    color: white;
  }
  transition: box-shadow ease-in-out 0.3s, background-color ease-in-out 0.3s;
  align-items: center;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  &.skatdklogo {
    width: 190px;
    @include media-breakpoint-down(sm) {
      width: 26px;
      background-image: url(../images/skatdk-logo-white-mobile.svg);
      background-size: 28px;
    }
    background-image: url(../images/skatdk-logo-white-full.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
  &.bg-skatdk {
    color: black;
    &:hover,
    &:focus {
      background-color: $blaa-60;
    }
  }
}

.sr-only-focusable.sr-only-focusable-center {
  position: absolute;
  left: 95px;
  top: 50%;
  transform: translate(-50%, -50%);
  color: $blaa;
}

$toggle-size: 0.6em;
.with-caret {
  &::after {
    content: '';
    @include trekant($toggle-size);
    display: block; //IE vil have elementet skal være defineret som block
    transition: transform ease-in-out 0.2s;
    margin-left: 10px;
    position: absolute;
    right: 14px;
    top: 22px;
  }

  &[aria-expanded='true']::after {
    transform: rotate(0.5turn);
  }
}

@include media-breakpoint-down(sm) {
  .with-caret {
    &::after {
      display: none;
    }
  }
}

.infocontent {
  position: absolute;
  max-width: 100vw;
  background-color: white;
  border-left: 1px solid $blaa;
  border-right: 1px solid $blaa;
  border-bottom: 1px solid $blaa;
  padding: math.div($grid-gutter-width, 4);
  [aria-expanded='false'] + & {
    display: none;
  }
}

@include media-breakpoint-down(sm) {
}

.skatdk-header {
  border-bottom: 6px solid $sktst-highlight;
}
