@import '../../fonts/ikoner/variables.scss';

$size: 1.7rem;

.ikon.ikon--large {
  font-size: 1.5rem;
}

.rounded-icon,
.ikon-rundt {
  color: white;

  &.ikon-rundt--inverted,
  &.rounded-icon--inverted,
  .alert-primary &,
  .alert-danger & {
    color: $blaa;
    background-color: white;

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25);
    }
  }
  border-radius: 50%;
  width: $size;
  height: $size;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: $blaa;
  text-decoration: none;
  border: none;

  &:hover {
    color: white;
    background-color: $blaa-60;
    text-decoration: none;
  }

  &:focus {
    background-color: $blaa-60;
    outline: 0;
    box-shadow: $custom-control-indicator-focus-box-shadow;
  }

  &--inverted {
    background-color: white;
    color: $blaa;

    &:hover {
      background-color: $blaa-20;
      color: $blaa;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem rgb(148, 148, 148);
    }
  }
}

.upload-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

  &:focus {
    + label {
      box-shadow: $alt-btn-focus;
      .rounded-icon,
      .ikon-rundt {
        background-color: $blaa-60;
      }
    }
  }

  + label {
    cursor: pointer;
    display: inline-block;
    &:hover {
      text-decoration: underline;
    }
  }
}

.ikon-download:before {
  content: $ikon-upload;
  transform: rotate(0.5turn) translateY(-0.125rem);
}

.btn .ikon {
  display: flex;
  justify-content: center;
}
