.modal {
  &-header {
    border-bottom: none;

    .close {
      width: 2.5rem;
      height: 2.5rem;
      background: $blaa url(../images/ikoner/ikon-luk--hvid.svg) center center
        no-repeat;
      background-size: 1.6rem 1.6rem;
      opacity: 1;
    }
  }

  &-title {
    margin: 0;
  }

  &-footer {
    justify-content: space-between;
    flex-direction: row-reverse;
  }
}

.modal-backdrop {
  z-index: 4;
}


button.close {
  &:focus {
    box-shadow: $btn-focus-box-shadow;
    outline: solid 1px white;
  }
}
