//Blue main colours
$blaa: #14143c;
$blaa-80: #434363;
$blaa-60: #72728a;
$blaa-40: #a1a1b1;
$blaa-20: #d0d0d8;
$blaa-hover-color: #464665;

//Grey colours
$graa: #e5e5e5;
$baggrund-graa: $graa;

//Sand colours
$sand: #d1c5c3;
$sand-50: #e8e2e1;

// skat.dk
$skatdk: #2e99d9;

//Udviklings- og Forenklingsstyrelsen mellemblå
$ufst: #1c69b9;
$ufst-60: #77a5d5;
$ufst-30: #bbd2ea;

//Administrations- og Servicestyrelsen limegrøn
$adst: #aac800;
$adst-60: #ccde66;
$adst-30: #e5eeb2;

//Motorstyrelsen grøn
$motorst: #008139;
$motorst-60: #66b388;
$motorst-30: #b2d9c4;

//Toldstyrelsen gul
$toldst: #ffbb16;
$toldst-60: #ffd673;
$toldst-30: #ffebb9;

//Vurderingsstyrelsen orange
$vurdst: #ff620c;
$vurdst-60: #ffa16d;
$vurdst-30: #ffd0b6;

//Gældsstyrelsen rød
$gaeldst: #df0515;
$gaeldst-60: #ec6973;
$gaeldst-30: #f5b4b9;

//Skattestyrelsen dueblå
$sktst: #aecbda;
$sktst-60: #cee0e9;
$sktst-30: #e7eff4;

//Skattestyrelsen highlight blå
$sktst-highlight: #2e99d9;
$sktst-highlight-60: #82c2e8;
$sktst-highlight-30: #c0e0f4;

$colors: (
  graa: $graa,
  sand: $sand,
  skatdk: $skatdk,
  ufst: $ufst,
  adst: $adst,
  motorst: $motorst,
  toldst: $toldst,
  vurdst: $vurdst,
  gaeldst: $gaeldst,
  sktst: $sktst,
);

@each $name, $color in $colors {
  .bg-#{$name} {
    background-color: $color;
  }

  .text-#{$name} {
    color: $color;
  }
}

.bg-skatdk {
  &.bg-hover,
  &.bg-focus {
    transition: background-color 0.3s;
  }
  &.bg-hover:hover,
  &.bg-focus:focus {
    background-color: $ufst-60;
  }
}
