@use 'sass:math';
@import '~bootstrap/scss/pagination';

$sort-size: 0.5rem;

.dropdown-toggle:focus {
  box-shadow: $custom-control-indicator-focus-box-shadow;
}

.table {
  height: 1px;

  caption {
    caption-side: top;
    color: inherit;
    font-weight: bold;
    margin: 0;

    &.table-status {
      padding: 0;
      background-color: $blaa;
      color: white;

      > div {
        display: flex;
        position: relative;
        padding: 1rem $table-cell-padding;
        align-items: center;

        &::after {
          content: '';
          @include trekant(0.75rem);
          position: absolute;
          bottom: -10px;
          left: 30px;
          border-top-color: $blaa;
        }

        :last-child {
          margin-left: auto;
        }
      }
    }
  }

  tr.deleting {
    td {
      opacity: 0.5;
    }
  }

  thead {
    $header-border: solid 2px $blaa;

    tr:first-child {
      th,
      td {
        border-top: $header-border;
      }
    }
    tr:last-child {
      th,
      td {
        border-bottom: $header-border;
      }
    }
    th {
      vertical-align: top;
      font-weight: normal;

      &.sortable {
        padding: 0;

        > .btn {
          padding: calc(#{$table-cell-padding} - 2px);
          padding-right: 36px;
          text-align: left;
          width: calc(100% - 4px);
          position: relative;
          margin: 0 2px;
        }

        &.sorted {
          > .btn {
            font-weight: bold;

            &::after {
              content: '';
              position: absolute;
              right: $table-cell-padding;
              top: calc(50% - #{math.div($sort-size, 2)});
              @include trekant($sort-size);
            }
          }
          &.ascending {
            > .btn {
              &::after {
                transform: rotate(0.5turn);
              }
            }
          }
        }
      }
    }
  }

  th,
  td {
    transition: opacity ease-in 0.5s;

    &.checkbox-cell {
      width: 24px;

      .custom-control {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 1.5rem;

        &-label {
          &::before {
            left: -1.5rem;
          }
          &::after {
            left: -1.5rem;
          }
        }
      }
    }

    .dropdown.action-dropdown {
      height: 1.5rem;
      min-width: 6rem;

      .dropdown-toggle {
        background-color: transparent;
        padding: 0rem;
        background-image: url(../images/actiondots.svg);
        background-repeat: no-repeat;
        background-position: 3px -1px;
        height: 1.5rem;
        min-width: 1rem;

        &:before,
        &:after {
          border: none;
        }

        &:focus {
          box-shadow: 0 0 0 3px #72728a;
          outline: none;
        }
      }

      .dropdown-menu {
        min-width: 7rem;
        white-space: nowrap;
        padding: 0;
        border: none;
        top: -4px;
        right: 1rem;
        left: auto;
        text-align: right;
        margin-right: 3px;
        height: 1.5rem;
      }

      .action {
        margin-right: 0.5rem;
      }
    }
  }

  tr.selected {
    background-color: $baggrund-graa;
  }

  tr:hover,
  tr.selected {
    td {
      &.actions-cell {
        .action {
          opacity: 1;
        }
      }
      .dropdown-menu {
        display: block;
        background-color: $baggrund-graa;
      }
    }
  }
}

@for $i from 1 through 5 {
  .width-#{$i}em {
    width: #{$i}em;
  }
}

.btn-table-cell {
  width: 100%;
  height: 100%;

  &:hover,
  &:focus {
    background-color: $blaa-60;
    color: white;
  }

  &:focus {
    border: solid 1px white;
    box-shadow: 0 0 0 0.2rem #72728a;
  }
}

.form-control-sm.paginator {
  width: 3em;
}

.custom-select.paginator {
  height: calc(1.5em + 0.5rem + 2px);
  padding-left: 0.25rem;
  padding-right: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  // TODO investigate background-position-x: right 0.5rem;
  background: #fff
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    right 0.5rem center/8px 10px no-repeat;
}
