.collapser {
  $triangle-size: 8px;

  margin: 1.25rem 0;

  &__header {
    width: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    line-height: 1.5em;
    color: $blaa;
    font-weight: 600;
    text-align: left;
    background: transparent;
    border: none;

    &.h4 {
      @extend .h4;
      line-height: 1.5em;
      margin-top: 1rem;
    }

    h3 > & {
      @extend .h3;
      line-height: 1.5em;
    }

    h2 > & {
      @extend .h2;
      line-height: 1.5em;
    }

    &:focus {
      outline: 0;
      box-shadow: $btn-focus-box-shadow;
    }

    > img {
      margin-right: 1rem;
    }

    &[aria-expanded='true'] {
      border-bottom: 1px solid transparent;
      &::before {
        transform: translate(-25%, -25%) rotate(225deg);
      }
    }

    &[aria-expanded='false'],
    &:hover:not(:focus) {
      border-bottom: 1px solid $blaa;
    }

    &::before {
      content: '';
      display: inline-block;
      flex-shrink: 0;
      width: $triangle-size;
      height: $triangle-size;
      margin-right: 1rem;
      margin-left: 1px;
      border-style: solid;
      border-width: 0 2px 2px 0;
      transform-origin: 75% 75%;
      transition: transform ease-in-out 0.3s;
      transform: translate(-25%, -25%) rotate(45deg);
    }
  }

  &__body {
    padding: 0.5rem 0 1.2rem 0;
    border-bottom: solid 1px $blaa;

    > :first-child {
      margin-top: 0;
    }
  }
}
