@use 'sass:math';

$spacing: 1rem;
$arrow-size: 0.5rem;

.breadcrumb {
  background-color: #ffffff;
  margin-bottom: 0;
  @include media-breakpoint-down(sm) {
    padding: math.div($grid-gutter-width, 2);
  }

  &-item {
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
      &:focus {
        color: $blaa-80;
      }
    }

    &:not(:last-child) {
      &:after {
        content: '';
        width: $arrow-size;
        height: $arrow-size;
        border: solid #434363;
        border-width: 2px 2px 0 0;
        margin: 0 $spacing;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
}
