dl {
  &.inverse {
    dt {
      font-weight: normal;
    }

    dd {
      font-weight: bold;
    }
  }

  &.dl-right {
    dt {
      text-align: right;
    }
  }
}
