.feedback {
  background-color: #e8e2e1;
  padding: 1rem;

  &__header {
    margin-bottom: 1rem;
  }

  button.btn {
    background-color: transparent;
    border: solid 1px gray;
    width: 10rem;
    transition: background-color ease-in-out 0.25s;

    &:hover,
    .active {
      background-color: $primary;
      color: white;
    }
  }
}
