@import 'colors';

.language-chooser {
  border: none;
  color: white;
  transition: background-color 0.3s;
  text-align: left;
  display: flex;
  align-items: center;
  border-bottom: var(--border--sm) solid transparent;
  cursor: pointer;
}

@include media-breakpoint-down(sm) {
  .language-chooser {
    position: relative;
    top: 0;
    display: inline-block;
  }
}

.language-chooser:focus,
.language-chooser:hover {
  outline: none;
  color: $blaa;
  box-shadow: 0 0 0 var(--border--md) var(--color-primary--60);
  box-shadow: $btn-focus-box-shadow inset;
}

@media (max-width: 768px) {
  .language-chooser::before {
    content: url(../images/ikoner/ikon-sprogvaelger--hvid.svg);
    position: relative;
    top: 15%;
    margin: auto;
    width: 14px;
    padding-top: 0;
    padding-bottom: 0;
    height: auto;
    align-items: center;
    display: flex;
  }
}

@media (min-width: 768px) {
  .language-chooser::after {
    content: url(../images/ikoner/ikon-sprogvaelger--hvid.svg);
    position: relative;
    top: 3px;
    width: 14px;
    height: 100%;
    align-items: center;
    display: flex;
    font-size: x-small;
  }
}

.language-chooser2 {
  display: flex;
  list-style-type: none;
}

.language-chooser2 li:not(:last-child)::after {
  content: '';
  background-color: #d0d0d8;
  width: 1px;
  height: 16px;
  position: absolute;
  right: 0.5rem;
  top: 6px;
}

.language-chooser2 li {
  padding-right: 1rem;
  position: relative;
}

.language-chooser2 li a[aria-current]:not([aria-current='false']) {
  text-decoration: none;
  cursor: text;
  font-weight: bold;
}

.language-chooser2 li a[aria-current]:not([aria-current='false'])::before {
  content: url(../images/ikoner/ikon-ok.svg);
  position: absolute;
  width: 14px;
  left: -19px;
}

ul.language-chooser2::before {
  content: url(../images/ikoner/ikon-sprogvaelger.svg);
  position: absolute;
  left: 5px;
  width: 14px;
  top: 10px;
}

@media (min-width: 768px) {
  .language-chooser::before {
    margin-right: 1rem;
  }
}
