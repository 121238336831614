@use 'sass:math';

.wizard-trin {
  padding-left: 0;
  margin: 1rem 0;
  display: flex;
  position: relative;
  justify-content: center;
  counter-reset: navbar;
  list-style: none;

  li {
    position: relative;
    height: 5rem;
    counter-increment: navbar;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 0 1 195px;
    min-width: 0;

    $circlesize: 40px;
    &::after {
      content: $ikon-ok;
      font-family: $icomoon-font-family;
      font-size: 0.6rem;
      padding: 13px;
      border: solid 1px black;
      border-radius: 50%;
      background-color: white;
      width: $circlesize;
      height: $circlesize;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 5;
      position: absolute;
      left: calc(50% - #{math.div($circlesize, 2)});
      bottom: 0;
    }

    span {
      white-space: nowrap;
      overflow: visible;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      padding: 0 12px;
    }
    .line {
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: math.div($circlesize, 2);
      left: 50%;
      border-top: solid 1px black;
    }

    &.active {
      font-weight: bold;
      &::after {
        border-width: 2px;
      }

      &,
      & ~ li {
        &::after {
          content: counter(navbar);
          font-size: 1rem;
          font-family: $font-family-sans-serif;
          background-image: none;
        }

        .line {
          border-top-style: dotted;
        }
      }

      & ~ li {
        color: $blaa-60;
        &::after {
          border-color: $blaa-60;
        }
      }

      &::after {
        font-weight: bold;
      }
    }

    &:first-child .line::before,
    &:last-child .line::after {
      border-top-style: none;
    }

    &:last-child {
      .line {
        display: none;
      }
    }
  }

  &--flag {
    li:last-child {
      &::after {
        content: $ikon-maalflag !important;
        font-family: $icomoon-font-family !important;
      }
    }
  }
}

$filled-before: url("data:image/svg+xml,%3csvg width='10' height='32' xmlns='http://www.w3.org/2000/svg'%3e%3cg%3e%3cpath d='m0,0l10,16l-10,16l10,0l0,-32l-10,0z' fill-opacity='null' stroke-opacity='null' stroke='%2314143c' stroke-width='0' fill='%2314143c' id='svg_2'/%3e%3c/g%3e%3c/svg%3e");
$filled-after: url("data:image/svg+xml,%3csvg width='10' height='32' xmlns='http://www.w3.org/2000/svg'%3e%3cg%3e%3cpath fill='%2314143c' stroke='%2314143c' d='m0,1l9,16l-9,16l0,-32l0,0z' id='svg_1'/%3e%3c/g%3e%3c/svg%3e");
$no-fill: url("data:image/svg+xml,%3csvg width='10' height='32' xmlns='http://www.w3.org/2000/svg'%3e%3cg%3e%3cpath stroke='%2314143c' d='m0,0l9.5,16l-9.5,16' fill='none'/%3e%3c/g%3e%3c/svg%3e");
$arrow-width: 10px;
$spacing: 10px;

.navbar-wizard {
  padding-left: 0;
  counter-reset: wizard;
  margin-bottom: 2rem;
  display: inline-flex;

  li {
    display: inline-flex;
    align-items: stretch;
    position: relative;
    color: $blaa;
    counter-increment: wizard;

    span {
      display: flex;
      align-items: center;
      border-top: solid 1px $blaa;
      border-bottom: solid 1px $blaa;
      padding-left: $arrow-width + $spacing;
      padding-right: $spacing;
      margin-left: -$arrow-width;
      white-space: nowrap;
    }

    &:before,
    &.active:after,
    &:last-child:after {
      content: '';
      display: inline-block;
      width: $arrow-width;
      height: 32px;
      background-image: $no-fill;
      background-repeat: no-repeat;
    }

    &.active,
    &:last-child {
      span {
        margin-right: -$arrow-width;
      }

      &:after {
        transform: translateX(9px);
      }
    }

    &.active {
      span {
        margin-left: -1px;
        padding-left: $spacing;
        color: white;
        background-color: $blaa;
      }
      + li {
        padding-left: $arrow-width;
        &:before {
          content: none;
        }
      }
      &:before {
        background-color: white;
        background-image: $filled-before;
      }
      &:after {
        background-image: $filled-after;
      }
    }
  }

  @mixin no-text() {
    li {
      span {
        width: 42px;
        overflow: hidden;
        white-space: nowrap;

        &:before {
          content: counter(wizard);
          margin-right: 20px;
        }
      }

      &.active {
        span {
          width: 32px;
          padding-left: 10px;
        }
      }
    }
  }

  &--hide-text {
    @include no-text();
  }

  @include media-breakpoint-down('sm') {
    @include no-text();
  }
}
