.alert {
  max-width: 650px;
  margin: auto;
  border-width: 2px;
  > :last-child {
    margin-bottom: 0;
    border-width: 2px;
  }
  a {
    color: inherit;
  }
  // måske skal nedenstående hexkoder ændres til variabler fra farvebiblioteket?
  // eller også er det begreberne info, warning, danger og success, hvis farver skal ændres?
  &-info {
    background-color: $sktst-highlight-30;
    border-color: $primary;
  }
  &-warning {
    background-color: $toldst-30;
  }
  &-danger {
    background-color: $gaeldst-30;
  }
  &-success {
    background-color: $motorst-30;
    border-color: $motorst;
  }
  &__header {
    font-weight: bold;
    font-size: 1.5rem;
    margin-top: 0;
    margin-bottom: 1rem;
    color: $primary;
  }
  &-warning,
  &-success,
  &-alert,
  &-danger {
    p {
      color: $primary;
    }
  }
  &--light {
    background-color: white;
    color: $primary;
    p {
      color: $primary;
    }
  }
  //alert-icon
  &-icon {
    > .alert__header {
      padding-left: 45px;
      position: relative;
      margin-top: 0;
      &::before {
        height: 32px;
        display: flex;
        position: absolute;
        left: 0;
        top: -2px;
      }
    }
  }
  &-success.alert-icon {
    > .alert__header {
      &::before {
        content: url(../images/ikoner/ikon-ok--groen.svg);
      }
    }
  }
  &-info.alert-icon {
    > .alert__header {
      &::before {
        content: url(../images/ikoner/ikon-info--blaa.svg);
      }
    }
  }
  &-warning.alert-icon {
    > .alert__header {
      &::before {
        content: url(../images/ikoner/ikon-warning--gul.svg);
      }
    }
  }
  &-danger.alert-icon {
    > .alert__header {
      &::before {
        content: url(../images/ikoner/ikon-afvist--roed.svg);
      }
    }
  }
}

.ikon.ikon-groen,
.ikon.ikon-blaa,
.ikon.ikon-gul,
.ikon.ikon-roed {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
}

.ikon.ikon-groen {
  background-color: $motorst;
  color: white;
}

.ikon.ikon-groen::after {
  content: $ikon-ok;
  position: absolute;
  top: 10px;
  left: 8px;
  font-size: 13px;
}

.ikon.ikon-blaa {
  background-color: $primary;
  color: white;
}

.ikon.ikon-gul {
  background-color: $toldst;
  color: $primary;
  outline: 1px solid $primary;
}

.ikon.ikon-roed {
  background-color: $gaeldst;
  color: white;
}

.ikon.ikon-blaa::after,
.ikon.ikon-gul::after,
.ikon.ikon-roed::after {
  content: '!';
  position: absolute;
  top: 4px;
  left: 12px;
  font-family: 'Academy Sans', 'Academy Sans Office', sans-serif;
  font-size: 24px;
}
