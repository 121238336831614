.navbar-content {
  position: absolute;
  right: 30px;
}

.navbar.navbar-dark {
  a,
  a:not([href]) {
    color: white;
    display: inline-block;
    text-decoration: none;
    padding: auto;
    transition: background-color ease-in-out 0.25s;

    &.disabled {
      color: $blaa-60;
      cursor: not-allowed;
    }

    &:hover {
      text-decoration: none;
      &.disabled {
        background-color: $blaa;
      }
    }
  }
}

.navbar-toggler {
  color: $navbar-dark-color;
  border-color: $navbar-dark-toggler-border-color;
}

.navbar-toggler-icon {
  height: 0.8em;
}

@include media-breakpoint-down('xl') {
  header .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
    margin-right: 1rem;
    margin-left: 1rem;
    text-decoration: none;
  }
}

@include media-breakpoint-down('sm') {
  header .nav-item .nav-link header .nav-item .nav-link.disabled,
  header .nav-item .nav-link:hover {
    display: block;
    border-right: none;
  }
}
